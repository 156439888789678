import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import MenuSidebar from "../components/MenuSidebar";
import "./pages.css";
import { Modal } from "antd";
import Select from '@mui/material/Select';
import userService from "../service/user.service";
import { toast } from "react-toastify";
import BankList from "../components/BankList";
import Loading from "../components/Loading";
import { ConfigContext } from "../ConfigContext";
import TransitionsModal from "../components/common/TransitionsModal";
import { Button, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import BankAccounts2 from "../components/layout/BankAccounts2/BankAccounts2";

// const { Option } = Select;

function BankAccounts({ getWalletInfo, walletInfo }) {
  const [bankModal, showBankModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [myBanks, setMyBanks] = useState([
    {
      name: "",
      icon: "",
      number: "",
      metaData: {
        name: "",
      },
    },
  ]);
  const [accError, setAccError] = useState("");
  const [bankError, setBankError] = useState("");
  const configData = useContext(ConfigContext);
  const {t} = useTranslation();


  const getBankList = async () => {
    setLoading(true);
    const banklist = await userService.getBanks();
    if (banklist.data) {
        const bankshow = banklist.data.filter(item => item.active);
        setBanks(bankshow);
    }

    // setBanks(getBanks.data);
    setLoading(false);
  };

  const fetchWallet = async () => {
    setLoading(true);
    try {
      // const wallet = await userService.getBalance();
      // const userId = wallet.data.user.id;
      // setUserId(userId);

      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }

      let banks = [];
      customerBank.map((bank) =>
        banks.push({
          ...bank,
          metaData: {
            name: "",
          },
        })
      );

      const bank = customerBank[0];
      setFullName(bank.name);

      let rafactoredBanks = [];
      // fetch bank meta data
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        banks.map((b) =>
          rafactoredBanks.push({
            ...b,
            metaData: bankData.data,
          })
        );
        setMyBanks(rafactoredBanks);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleAddBank = async () => {
    setBankError("");
    setAccError("");
    try {
      if (selectedBank === "") {
        setBankError("Please select bank!");
      }
      if (accNumber === "") {
        setAccError("Please enter account number!");
      }
      if (selectedBank === "" || accNumber === "") {
        return;
      }
      setLoading(true);

      let wallet = "";
      let user = "";
      if (
        walletInfo &&
        walletInfo.id &&
        walletInfo.user &&
        walletInfo.user.id
      ) {
        wallet = walletInfo.id;
        user = walletInfo.user.id;
      }

      const values = {
        wallet,
        user,
        number: accNumber,
        name: fullName,
        bank: selectedBank,
      };

      const result = await userService.addCustomerBank(values);
      if (!result.data.id) {
        setLoading(false);
        showBankModal(false);
        return toast.error("Bank account with selected bank already exist!");
      }
      toast.success("Bank addedd successfully!");
      fetchWallet();
    } catch (err) {
      console.log(err);
      if (err.response) {
        const data = err.response.data;
        if (data.type) {
          setAccError("Type field is required!");
        } else if (data.icon) {
          setAccError(data.icon[0]);
        } else if (data.number) {
          setAccError(data.number[0]);
        }
        return toast.error("Something went wrong! Please try again");
      }
    }
    setLoading(false);
    showBankModal(false);
  };

  const handleAccountNumber = (e) => {
    const RE = /[0-9\b]$/;
    if (RE.test(e.target.value) || e.target.value === "") {
      setAccNumber(e.target.value);
    }
  };

  useEffect(() => {
    getBankList();
  }, []);

  return (
    <>
      {
        (configData && (configData.bank_accounts_style == "1")) || (configData && !configData.bank_accounts_style) ?
          <div className="t1">
            <div className="page">
              <div className="page-content footer-clear">
                <div className="content">
                  <Button
                    onClick={() => showBankModal(true)}
                    style={{
                      width: "100%",
                      margin: "1rem 0",
                      height: "40px",
                      backgroundColor: configData?configData.secondary_color:"",
                      color: "white",
                      border :"none"
                    }}
                  >
                    {t("Add_Bank") + " " + t("Account")}
                  </Button>
                  <BankList
                    setFullName={setFullName}
                    myBanks={myBanks}
                    fetchWallet={fetchWallet}
                  />
                </div>
              </div>
              <Footer />
              <MenuNotification />
              <MenuSidebar />
            </div>
            <TransitionsModal
              open={bankModal}
              onClose={() => {
                showBankModal(false);
                setSelectedBank("");
              }}
              title={t("Add_Bank") + " " + t("Account")}
              style={{ textAlign: "left" }}
            >
              <div>
                <h4>{t("Full_Name")}</h4>
                <input
                  className="form-control rounded-xs text-white"
                  id="c1a"
                  style={{ marginBottom: "1rem", marginTop: "6px" }}
                  placeholder="Full Name"
                  readOnly
                  value={fullName}
                />
                <h4>{t("Select_Bank")}</h4>
                <Select
                  style={{ width: "100%", marginTop: "6px", background: "white", height: "40px", borderRadius: "8px" }}
                  onChange={(e) => setSelectedBank(e.target.value)}
                  placeholder="Select a Bank"
                  value={selectedBank}
                >
                  {banks.map((b, index) => (
                    <MenuItem value={b.id} key={index}>
                      <div className="flex flex-row gap-2">
                        {/* <img src={b.icon} className="w-12 h-8 rounded-sm" /> */}
                        <p style={{color: "black"}} className="text-black">{b.name}</p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                <div
                  style={{ paddingBottom: "1rem" }}
                  className={`${
                    bankError === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                >
                  {bankError}
                </div>

                <h4>{t("Account_Number")}</h4>
                <input
                  className="form-control rounded-xs text-white"
                  id="c1a"
                  onChange={handleAccountNumber}
                  value={accNumber}
                  style={{ marginTop: "6px" }}
                  placeholder={t("Account_Number")}
                />
                <div
                  style={{ paddingBottom: "1rem" }}
                  className={`${
                    accError === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                >
                  {accError}
                </div>
                <Button
                  variant="contained"
                  onClick={handleAddBank}
                  style={{
                    background: "#E6960C",
                    color: "white",
                  }}
                >
                  {t("Add_Bank")}
                </Button>
              </div>
            </TransitionsModal>
            {loading && <Loading />}
          </div>
          : null
      }
      {
        (configData && (configData.bank_accounts_style == "2")) ?
          <>
            <BankAccounts2 myBanks={myBanks} loading={loading} fetchWallet={fetchWallet} addBankHandler={() => { showBankModal(true) }}
            />
            <TransitionsModal
              open={bankModal}
              onClose={() => {
                showBankModal(false);
                setSelectedBank("");
              }}
              title={t("Add_Bank") + " " + t("Account")}
              style={{ textAlign: "left" }}
            >
              <div>
                <h4>{t("Full_Name")}</h4>
                <input
                  className="form-control rounded-xs text-white"
                  id="c1a"
                  style={{ marginBottom: "1rem", marginTop: "6px" }}
                  placeholder="Full Name"
                  readOnly
                  value={fullName}
                />
                <h4>{t("Select_Bank")}</h4>
                <Select
                  style={{ width: "100%", marginTop: "6px", background: "white", height: "40px", borderRadius: "8px" }}
                  onChange={(e) => setSelectedBank(e.target.value)}
                  placeholder="Select a Bank"
                  value={selectedBank}
                >
                  {banks.map((b, index) => (
                    <MenuItem value={b.id} key={index}>
                      <div className="flex flex-row gap-2">
                        {/* <img src={b.icon} className="w-12 h-8 rounded-sm" /> */}
                        <p style={{ color: "black" }} className="text-black">{b.name}</p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                <div
                  style={{ paddingBottom: "1rem" }}
                  className={`${bankError === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                >
                  {bankError}
                </div>

                <h4>{t("Account_Number")}</h4>
                <input
                  className="form-control rounded-xs text-white"
                  id="c1a"
                  onChange={handleAccountNumber}
                  value={accNumber}
                  style={{ marginTop: "6px" }}
                  placeholder={t("Account_Number")}
                />
                <div
                  style={{ paddingBottom: "1rem" }}
                  className={`${accError === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                >
                  {accError}
                </div>
                <Button
                  variant="contained"
                  onClick={handleAddBank}
                  style={{
                    background: "#E6960C",
                    color: "white",
                  }}
                >
                  {t("Add_Bank")}
                </Button>
              </div>
            </TransitionsModal>
          </>
          : null
      }
    </>
  );
}

export default BankAccounts;
