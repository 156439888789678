import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import userService from "../service/user.service";
import Footer from '../components/Footer';
// import backgroundImg from '../../src/images/ECI88_Background.png';
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

const ENABLE_SUREPAY = process.env.REACT_APP_ENABLE_SUREPAY === "1";
// const ENABLE_DGPAY = process.env.REACT_APP_ENABLE_DGPAY === "1";
const ENABLE_SUREPAY_EWALLET = process.env.REACT_APP_ENABLE_SUREPAY_EWALLET === "1";
const ENABLE_EASYPAY = process.env.REACT_APP_ENABLE_EASYPAY === "1";

const Deposit = () => {
  // const [typelist, setTypelist] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // const [ewalletsList, setEWalletsList] = useState([]);

  const configData = useContext(ConfigContext)

  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    // getCouponTypes();
    getMerchantEWalletslist();
  }, [])

  // const getCouponTypes = async () => {
  //   setLoading(true);
  //   const typelists = await userService.getCouponTypelist();
  //   if (typelists) {
  //     const typeArr = typelists.data.map((item) => {
  //       return {
  //         id: item[0],
  //         name: item[1]
  //       }
  //     })
  //     // setTypelist(typeArr);
  //   }
  //   setLoading(false);
  // }

  const getMerchantEWalletslist = async () => {
    setLoading(true);
    const ewallets = await userService.getMerchantEWallets();
    // setEWalletsList(ewallets.data);
    setLoading(false);
  }

  // const getBackgroundClass = (item) => {
  //   if (item.name === "TNG") {
  //     return "tng-background";
  //   } else if (item.name === "DQR") {
  //     return "dqr-background";
  //   }
  //   return "";
  // }

  return (
    <div className="deposit-container">
      <div className="deposit-title" style={{background: configData?configData.deposit_withdraw_title_bg :""}}>
        <h6 className="deposit-texttitle" style={{ color: configData?configData.primary_color:"" }}>{(configData && configData.deposit_title)?configData.deposit_title:((configData && configData.static_deposit_title) ? t(configData.static_deposit_title) : "")}</h6>
      </div>
      <div className="deposit-wrap d-flex flex-wrap justify-center align-center">

        { ENABLE_EASYPAY &&
        <div className="deposit_payment" onClick={() => navigate('/online-transfer/easypay')}>
          <div className="easypay-background"  style={{ backgroundImage: `url(${configData?configData.deposit_easypay:""})` }}>
            <div className="d-flex flex-col w-full justify-center">

            </div>
          </div>
        </div> }
        { ENABLE_SUREPAY &&
        <div className="deposit_payment" onClick={() => navigate('/online-transfer/surepay')}>
          <div className="surepay-background" style={{ backgroundImage: `url(${configData?configData.deposit_surepay:""})` }}>
            <div className="d-flex flex-col w-full justify-center">

            </div>
          </div>
        </div> }
        {/* { ENABLE_DGPAY &&
        <div className="deposit_payment" onClick={() => navigate('/online-transfer/dgpay')}>
          <div className="surepay-background" style={{ backgroundImage: `url(${configData?configData.deposit_surepay:""})` }}>
            <div className="d-flex flex-col w-full justify-center">

            </div>
          </div>
        </div> } */}
        <div className="deposit_payment" onClick={() => navigate('/manual-transfer')}>
          <div className="transfer-background" style={{ backgroundImage: `url(${configData?configData.deposit_manual_deposit :""})` }}>
            <div className="d-flex flex-col w-full justify-center">
            </div>
          </div>
        </div>
        {ENABLE_SUREPAY_EWALLET &&
        <div className="deposit_payment" onClick={() => navigate('/ewallet-transfer')}>
          <div className="ewallet-background" style={{ backgroundImage: `url(${configData?configData.deposit_ewallet :""})` }} >
            <div className="d-flex flex-col justify-center" style={{ width: "calc(100% - 45px)" }}>
            </div>
          </div>
        </div> }
      </div >
      <div className="t1">
        <Footer />
      </div>
      {isLoading && <Loading />}
    </div >
  );
};

export default Deposit;
