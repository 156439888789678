function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const possibleDeposits = [10, 30, 50, 100, 200, 250, 300, 500]

export function generateMockData(numberOfRecords=20) {
  const prefixes = ['6010', '6011', '6012', '6014', '6016', '6017', '6019'];
  const mockData = [];

  for (let i = 0; i < numberOfRecords; i++) {
    // Select a random prefix
    const prefix = prefixes[getRandomInt(0, prefixes.length - 1)];
    let record = prefix + getRandomInt(0, 9);

    // Generate the masked part
    const maskedLength = prefix === '011' ? 4 : 3;
    let masked = '';
    for (let j = 0; j < maskedLength; j++) {
        masked += '*';
    }
    record += masked;

    // Add two numbers at the end
    record += String(getRandomInt(10, 99));
    mockData.push(record);
  }

  return mockData;
}


export const mockedProductNames = ["918kiss","Pussy888","Jili","Mega888"]