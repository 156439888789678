import React, { useContext, useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { Disclosure } from '@headlessui/react'
import { Divider } from 'antd';
import userService from '../service/user.service';
import { ConfigContext } from '../ConfigContext';

function BetHistory() {
    const [betHistory, setBetHistory] = useState([]);
    // const [loading, setLoading] = useState(false)
    const configData = useContext(ConfigContext)

    const getBetHistory = async () => {
        try {
            // setLoading(true)
            const wallet = await userService.getBalance();
            const userId = wallet.data.user.id;
 


            const betHistory = await userService.getBetHistory(userId);
            console.log(betHistory);
            setBetHistory(betHistory.data)
            // setLoading(false)
        } catch (err) {
            console.log(err)
            // setLoading(false)
        }
    }

    useEffect(() => {
        getBetHistory();
    }, [])

    return (
        <div id="menu-notifications"
            className="rounded-m d-block mx-2"
        // style={{ backgroundColor: "#fff" }}
        >
            <div className="menu-size" style={{ height: "75vh" }}>
                <div className="content pb-2">
                    {/* <a href="#" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1 color_yellow">Meg888 - Slot 1 Bet $100 Win $100</h5>
                            <p className="mb-0 font-11 opacity-70 color-white">14th March 03:14 AM</p>
                        </div>
                    </a> */}
                    <div className="divider my-2 opacity-50"></div>

                    <div className={`list-group list-custom list-group-m rounded-xs`}>
                        {betHistory && betHistory.length > 0 && betHistory.map(item => {
                            return (
                                <Disclosure as="div" className="mt-1">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="bethistory-item d-flex w-full justify-between  px-4 py-2">
                                                <div>
                                                    <span className='text-base d-flex items-end gap-2'>
                                                        {item && item.detail && item.detail.GameName}
                                                        <span className='text-stone-900 text-sm'>Bet {item.bet}</span>
                                                        <span className={`${Number(item.winlose) < 0 ? 'text-red' : 'text-green'} text-sm`}>
                                                            {(Number(item.winlose) < 0) ? 'Lose' : 'Win'} {item.winlose}
                                                        </span>
                                                    </span>
                                                    <span className='font-[400] text-stone-500' style={{ color: "#aab2bd" }}>{new Date(item.matchtime).toLocaleString()}</span>
                                                </div>
                                                <div style={{ marginTop: "10px" }}>
                                                    <Icon icon="mingcute:down-line" className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-sky-500`} />
                                                </div>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className=" px-4 pt-4 pb-2 text-sm text-gray-500" style={{ color: "#aab2bd" }}>
                                                <div className='d-flex gap-2 text-sm font-semibold'>
                                                    <span className='text-red-500'>{item.detail.Type}</span>
                                                    <span className='text-red-500 d-flex'>ID: &nbsp; <p style={{ color: "#aab2bd" }}>{item.betid}</p></span>
                                                    <span className='text-red-500 d-flex'> AppID:  &nbsp; <p style={{ color: "#aab2bd" }}>{item.detail.AppID}</p></span>
                                                </div>
                                                <Divider className='font-white my-2 text-stone-500' />
                                                <div className='text-sm'>
                                                    <div>Start Balance: <span style={{color : configData?configData.text_color :""}}>
                                                        {item.detail.StartBalance}</span> <span className='text-sm'>{item.detail.CurrencyCode}
                                                        </span>
                                                    </div>
                                                    <div>End Balance: <span style={{color : configData?configData.text_color :""}}>
                                                        {item.detail.EndBalance}</span> <span className='text-sm'>{item.detail.CurrencyCode}
                                                        </span>
                                                    </div>
                                                    <div>Payout: <span style={{color : configData?configData.text_color :""}}>
                                                        {item.payout}</span> <span className='text-sm'>{item.detail.CurrencyCode}
                                                        </span>
                                                    </div>
                                                    <div>Turnover: <span style={{color : configData?configData.text_color :""}}>
                                                        {item.turnover}</span> <span className='text-sm'>{item.detail.CurrencyCode}
                                                        </span>
                                                    </div>
                                                </div>
                                                <Divider className='font-white my-2' />
                                                {item.detail.Description}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BetHistory