import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "../service/auth.service";
import Loading from "../components/Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";
import tokenService from "../service/token.service";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import SignIn2 from "../components/layout/SignIn/SignIn2";
import SignIn3 from "../components/layout/SignIn/SignIn3";
import SignIn4 from "../components/layout/SignIn/SignIn4";

const initialValues = {
  username: "",
  password: "",
};

function SignIn({ setAuth }) {
  const [values, setValues] = useState(initialValues);
  const configData = useContext(ConfigContext);
  const { t } = useTranslation();

  const [validations, setValidations] = useState({
    username: "",
    password: "",
    refresh: "",
  });
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token_id = searchParams.get("token_id");
  const signupVersion = configData && configData.signup_version ? configData.signup_version : process.env.REACT_APP_SIGNUP_VERSION;

  useEffect(() => {
    if (token_id) {
      tokenService.removeUser();
      handleStealthLogin(token_id);
    }
  }, [token_id]);

  const handleStealthLogin = async (tokenId) => {
    setLoading(true);
    try {
      const { data } = await authService.stealthLogin(tokenId);
      if (data && data.access) {
        setTimeout(() => {
          tokenService.setUser(data);
          setLoading(false);
          navigate("/");
          setAuth(true);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const {
    username: nameVal,
    password: passVal,
    refresh: refreshVal,
  } = validations;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await authService.login(values);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      navigate("/");
      setAuth(true);
    } catch (err) {
      console.log("err ==> ", err);
      let username_msg;
      let password_message;
      let refresh_msg;
      if (err.response && err.response.data && err.response.data.username) {
        username_msg = err.response.data.username;
      }
      if (err.response && err.response.data && err.response.data.refresh) {
        refresh_msg = err.response.data.refresh;
      }
      if (err.response && err.response.data && err.response.data.detail) {
        refresh_msg = err.response.data.detail;
      }
      if (err.response && err.response.data && err.response.data.password) {
        password_message = err.response.data.password;
      }
      setValidations({
        ...validations,
        username: username_msg,
        password: password_message,
        refresh: refresh_msg,
      });
      setLoading(false);
    }
  };

  return (
    <>
    {
        (configData && (configData.login_style == "1")) || (configData && !configData.login_style) ?
      <div className="t1">
        <div id="page">
          <div className="page-content">
            <div
              data-card-height="cover-full"
              className="background_class d-flex flex-column justify-content-center" 
            >
              <div className="d-flex align-items-center justify-content-center">
                <img src={configData?configData.login_image :""} alt="" style={{width:"100%", height:"auto", maxHeight: "250px"}} />
              </div>
              <div className="px-4">
                <div>
                  <h1
                    className="font-23 font-700"
                    style={{
                      color: configData ? configData.text_color_secondary : "",
                    }}
                  >
                    {t("LOGIN")}
                  </h1>
                </div>
                <p
                  className="input-title mb-2 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Mobile_No")} / {t("Username")}
                </p>
                <div>
                  <div
                    className={`input-style has-borders has-icon validate-field mb-2  ${
                      values.username ? "input-style-active" : ""
                    }`}
                  >
                    <input
                      name="username"
                      type="text"
                      className={`form-control validate-name text-white`}
                      id="form1"
                      placeholder="Username"
                      onChange={handleChange}
                    />
                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                    <i className="fa fa-check disabled valid color-green-dark"></i>
                    {/* <em>(required)</em> */}
                    <div
                      className={`${
                        nameVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                    >
                      {nameVal}
                    </div>
                    <div
                      className={`${
                        refreshVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                    >
                      {refreshVal}
                    </div>
                  </div>
                  {/* <div className={`input-style input-transparent no-borders has-icon validate-field mb-4  ${data.user ? "input-style-active" : ""}`}>
                                      <i className="ps-1 fa fa-user"></i>
                                      <input onChange={e => setData({ ...data, user: e.target.value })} type="name" className={`form-control validate-name`} id="form1a" placeholder="Username" />
                                      <label htmlFor="form1a" className="color-highlight">Username</label>
                                      <i className="fa fa-times disabled invalid color-red-dark"></i>
                                      <i className="fa fa-check disabled valid color-green-dark"></i>
                                      <em>(required)</em>
                                  </div> */}
                  <p
                    className="input-title mb-2 font-14"
                    style={{ color: configData ? configData.text_color : "" }}
                  >
                    {t("Password")}
                  </p>
                  <div
                    className={`input-style has-borders has-icon validate-field mb-4  ${
                      values.password ? "input-style-active" : ""
                    }`}
                  >
                    <input
                      name="password"
                      type="password"
                      className={`form-control validate-password text-white`}
                      id="form1a"
                      placeholder="Password"
                      onChange={handleChange}
                    />
                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                    <i className="fa fa-check disabled valid color-green-dark"></i>
                    {/* <em>(required)</em> */}
                    <div
                      className={`${
                        passVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                    >
                      {passVal}
                    </div>
                  </div>
                  {/* <div className={`input-style input-transparent no-borders has-icon validate-field mb-4  ${data.password ? "input-style-active" : ""}`}>
                                      <i className="ps-1 fa fa-lock"></i>
                                      <input onChange={e => setData({ ...data, password: e.target.value })} type="password" className={`form-control validate-password`} id="form1a" placeholder="Password" />
                                      <label htmlFor="form1a" className="color-highlight">Password</label>
                                      <i className="fa fa-times disabled invalid color-red-dark"></i>
                                      <i className="fa fa-check disabled valid color-green-dark"></i>
                                      <em>(required)</em>
                                  </div> */}
                  <div
                    data-back-button
                    className="btn btn-full btn_green mt-4"
                    style={{
                      backgroundColor: configData
                        ? configData.secondary_color
                        : "",
                    }}
                    onClick={handleSubmit}
                  >
                    {t("LOGIN")}
                  </div>
                  {/* <div className="row pt-3 mb-3">
                                      <div className="col-6 text-start font-11">
                                      </div>
                                      <div className="col-6 text-end font-11">
                                          <a className="color-white opacity-50" href=".#" onClick={e => {
                                              e.preventDefault()
                                              navigate("/signup")
                                          }}>Create Account</a>
                                      </div>
                                  </div> */}
                  <p
                    className="input-title my-3 text-center font-14"
                    style={{ color: configData ? configData.text_color : "" }}
                  >
                    {t("Do_Not_Have")}
                  </p>

                  <div
                    data-back-button
                    className="btn mx-auto btn-full btn_green register_btn mt-2"
                    style={{
                      backgroundColor: configData
                        ? configData.secondary_color
                        : "",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (localStorage.getItem("from_refer")) {
                        localStorage.removeItem("from_refer");
                        navigate(-1);
                      } else {
                        navigate("/signup");
                      }
                    }}
                  >
                    {t("REGISTER")}
                  </div>
                  
                  <div
                    data-back-button
                    className="btn mx-auto btn-full btn_green register_btn mt-2"
                    style={{
                      backgroundColor: configData
                        ? configData.secondary_color
                        : "",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      signupVersion && signupVersion === "V3" ? (
                        navigate('/contactus')
                      ) : navigate("/forget-password");
                    }}
                  >
                    {t("Forget_Password")}
                  </div>
                </div>
              </div>
              <div className="t1">{/* <Footer /> */}</div>
            </div>
          </div>
          <Footer />
          <MenuNotification />
        </div>
        {isLoading && <Loading />}
      </div>
      :null
    }
    {
        (configData && (configData.login_style == "2")) ?
        <SignIn2 setAuth={setAuth} /> 
        :null
    }
    {
        (configData && (configData.login_style == "3")) ?
        <SignIn3 setAuth={setAuth} /> 
        :null
    }
    {
        (configData && (configData.login_style == "4")) ?
        <SignIn4 setAuth={setAuth} /> 
        :null
    }
    </>
  );
}

export default SignIn;
