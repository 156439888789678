import './component.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from 'react';
import userService from '../service/user.service';
import { useEffect } from 'react';
import { Empty } from 'antd';
import { ConfigContext } from '../ConfigContext';
import { useTranslation } from 'react-i18next';
import { generateMockData, mockedProductNames, possibleDeposits } from '../utils/mockData';

const COUNTRY = process.env.REACT_APP_COUNTRY;

function LiveTable() {
    const [liveTableData, setLiveTableData] = useState([])
    const [apiDeposits, setApiDeposits] = useState([])
    const [apiWithdraw, setApiWithdraw] = useState([])
    const [counter, setCounter] = useState(0)
    const configData = useContext(ConfigContext)
    const {t} = useTranslation();


    const getLiveTableData = async () => {
        try {
            let [{ data: depositData },{ data: withdrawData }] = await Promise.all([userService.getLastDeposits(),userService.getLastWithdrawals()])
            setApiDeposits(depositData)
            setApiWithdraw(withdrawData)
            const [filteredArray1, filteredArray2] = getFilteredApiData(depositData, withdrawData)
            depositData = filteredArray1;
            withdrawData = filteredArray2;

            let depositDataObj = {}
            depositData.map(v=>{
                if(depositDataObj[v.mobile]){
                    depositDataObj[v.mobile].push(v)
                }else{
                    depositDataObj[v.mobile] = [v]
                }
            })
            let withdrawDataObj = {}
            withdrawData.map(v=>{
                if(withdrawDataObj[v.mobile]){
                    withdrawDataObj[v.mobile].push(v)
                }else{
                    withdrawDataObj[v.mobile] = [v]
                }
            })
            depositData = []
            withdrawData = []
            Object.keys(depositDataObj).map(v=>{
                depositData.push(depositDataObj[v].shift())
            })
            Object.keys(withdrawDataObj).map(v=>{
                withdrawData.push(withdrawDataObj[v].shift())
            })
            let greaterLength = withdrawData.length > depositData.length ? withdrawData.length : depositData.length
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate()-5)

            depositData = (depositData.length < 20) ? depositData.concat(generateMockData(100).slice(0,((withdrawData.length > 20) ? withdrawData.length : 20) - depositData.length).map(record=>({
                mobile: record,
                amount: possibleDeposits[parseInt(Math.random()*7)],
                created_at: generateRandomDate(currentDate, new Date()).toISOString(),
            }))) : ((withdrawData.length > depositData.length) ? depositData.concat(generateMockData(Math.abs(withdrawData.length - depositData.length)).map(record=>({
                mobile: record,
                amount: possibleDeposits[parseInt(Math.random()*7)],
                created_at: generateRandomDate(currentDate, new Date()).toISOString(),
                product_name: mockedProductNames[parseInt(3 * Math.random())]
            }))) : depositData)
            withdrawData = (withdrawData.length < 20) ? withdrawData.concat(generateMockData(100).slice(0,((depositData.length > 20) ? depositData.length : 20) - withdrawData.length).map(record=>({
                mobile: record,
                amount: 500 - parseInt(470 * Math.random()),
                created_at: generateRandomDate(currentDate, new Date()).toISOString(),
                product_name: mockedProductNames[parseInt(3 * Math.random())]
            }))) : ((depositData.length > withdrawData.length) ? withdrawData.concat(generateMockData(Math.abs(withdrawData.length - depositData.length)).map(record=>({
                mobile: record,
                amount: parseInt(500 * Math.random()),
                created_at: generateRandomDate(currentDate, new Date()).toISOString(),
                product_name: mockedProductNames[parseInt(3 * Math.random())]
            }))) : withdrawData)

            greaterLength = withdrawData.length > depositData.length ? withdrawData.length : depositData.length
            const combinedData = []
            for (let length = 0; length < greaterLength; length++) {
                combinedData.push({
                    depositData: depositData[length],
                    withdrawData: withdrawData[length],
                })
            }
            setLiveTableData(combinedData)
        }catch(err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getLiveTableData();
    }, [])
    useEffect(() => {
        let interval
        if (liveTableData.length) {
            interval = setInterval(() => {
                setCounter(c => {
                    if (c<(liveTableData.length-5)) {
                        return c + 5
                    }
                    clearInterval(interval)
                    return 99999;
                })
            }, 5000);
        }
    }, [liveTableData])

    const getFilteredApiData = (depositData, withdrawData) => {
        const showedDeposit = JSON.parse(localStorage.getItem("showedDeposit"))
        const showedWithdrawl = JSON.parse(localStorage.getItem("showedWithdrawl"))
        let filteredArray1=[];
        let filteredArray2=[];
        if (!showedDeposit) {
            localStorage.setItem("showedDeposit", JSON.stringify(depositData.slice(0, 5)))
        } else {
            filteredArray1 = depositData.filter(item1 => !showedDeposit.some(item2 => JSON.stringify(item1) === JSON.stringify(item2)));
            const newArray1 = [...showedDeposit, ...filteredArray1.slice(0, filteredArray1.length>5?5:filteredArray1.length)];
            localStorage.setItem("showedDeposit", JSON.stringify(newArray1))
        }
        if (!showedWithdrawl) {
            localStorage.setItem("showedWithdrawl", JSON.stringify(withdrawData.slice(0, 5)))
        } else {
            filteredArray2 = withdrawData.filter(item1 => !showedWithdrawl.some(item2 => JSON.stringify(item1) === JSON.stringify(item2)));
            const newArray2 = [...showedWithdrawl, ...filteredArray2.slice(0, 5)];
            localStorage.setItem("showedWithdrawl", JSON.stringify(newArray2))
        }
        return [filteredArray1, filteredArray2];
    }
    useEffect(()=>{
        getFilteredApiData(apiDeposits, apiWithdraw)

        if(counter === 99999){
            const combinedData = liveTableData.slice(liveTableData.length-4,liveTableData.length)
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate()-5)
            let depositData = generateMockData().map(record=>({
                mobile: record,
                amount: possibleDeposits[parseInt(Math.random()*7)],
                created_at: generateRandomDate(currentDate, new Date()).toISOString(),
            }))
            let withdrawData = generateMockData().map(record=>({
                mobile: record,
                amount: parseInt(500 * Math.random()),
                created_at: generateRandomDate(currentDate, new Date()).toISOString(),
                product_name: mockedProductNames[parseInt(3 * Math.random())]
            }))
            for (let length = 0; length < 20; length++) {
                combinedData.push({
                    depositData: depositData[length],
                    withdrawData: withdrawData[length],
                })
            }
            setCounter(0)
            setLiveTableData(combinedData)
        }
    },[counter])


    function generateRandomDate(from, to) {
        return new Date(
          from.getTime() +
            Math.random() * (to.getTime() - from.getTime()),
        );
    }

    if (!liveTableData && !liveTableData.length) {
        return (
            <Empty description="No Live Deposits & Withdraws!" />
        )
    }

    const currency = configData && configData.currency;
    return configData && configData.live_table?(
        <TableContainer sx={{ maxHeight: 440, padding: "0 6px", textTransform: "uppercase", mt: 0 }}>
            <Table sx={{
                width: "100%",
                background: configData ? configData.live_transaction_bg : ""
            }}>
                {
                    liveTableData && liveTableData.length ?
                        <>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={5} sx={{ color: configData ? configData.text_color_secondary : "", padding: "6px", fontSize: "12px" }}>
                                        <Box sx={{ display: "flex" }}>
                                            {t("Live")} {t("Transaction")}
                                            <Box sx={{ color: configData ? configData.text_color : "", padding: "2px 0 2px 10px", borderRadius: "6px", background: "#EF0704", display: "inline-flex", alignItems: "center", marginLeft: "auto" }}>{t("Live")} <FiberManualRecordIcon /> </Box>
                                        </Box>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ padding: "6px", fontSize: "12px", color: configData ? configData.text_color : "", border: "1px solid #fff", background: configData ? configData.live_deposit_bg : "" }}>
                                        {t("Deposit")}
                                    </TableCell>
                                    <TableCell colSpan={3} sx={{ padding: "6px", fontSize: "12px", color: configData ? configData.text_color : "", border: "1px solid #fff", background: configData ? configData.live_withdraw_bg : "" }}>
                                        {t("Withdraw")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    liveTableData && liveTableData.length ?
                                        liveTableData.filter((tableDate, index) => ((index >= counter) && (index < counter + 5))).map((tableData, index) => {
                                            return (
                                                <TableRow key={tableData.depositData ? tableData.depositData.id : index}>
                                                    <TableCell sx={{ color: configData ? configData.text_color : "", padding: "6px", fontSize: "12px", border: "1px solid #fff" }}>
                                                        {tableData.depositData ? tableData.depositData.mobile ? tableData.depositData.mobile.substring(COUNTRY === 'my'? 1:2) : "" : ""}
                                                    </TableCell>
                                                    <TableCell sx={{ color: configData ? configData.text_color_secondary : "", padding: "6px", fontSize: "12px", border: "1px solid #fff" }}>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <span>{tableData.depositData ? (currency) + (!Number.isInteger(tableData.depositData.amount/1)?tableData.depositData.amount.toFixed(2):tableData.depositData.amount) : ""}</span>
                                                        {/* tableData.depositData && tableData.depositData.created_at ? <span style={{fontSize:"9px"}}>{tableData.depositData.created_at.slice(0,19).split("T").join(" ")}</span> : null */}
                                                    </div>
                                                    </TableCell>
                                                    <TableCell sx={{ color: configData ? configData.text_color : "", padding: "6px", fontSize: "12px", border: "1px solid #fff" }}>
                                                        {tableData.withdrawData ? tableData.withdrawData.mobile ? tableData.withdrawData.mobile.substring(COUNTRY === 'my'? 1:2) : "" : ""}
                                                    </TableCell>
                                                    <TableCell sx={{ color: configData ? configData.text_color_secondary : "", padding: "6px", fontSize: "12px", border: "1px solid #fff" }}>
                                                    <div style={{display:"flex",flexDirection:"column"}}>
                                                        <span>{tableData.withdrawData ? (currency) + (!Number.isInteger(tableData.withdrawData.amount/1)?tableData.withdrawData.amount.toFixed(2):tableData.withdrawData.amount) : ""}</span>
                                                        {/* tableData.withdrawData && tableData.withdrawData.created_at ? <span style={{fontSize:"9px"}}>{tableData.withdrawData.created_at.slice(0,19).split("T").join(" ")}</span> : null */}
                                                    </div>
                                                    </TableCell>
                                                    <TableCell sx={{ color: configData ? configData.text_color : "", padding: "6px", fontSize: "12px", border: "1px solid #fff" }}>
                                                        {tableData.withdrawData ? tableData.withdrawData.product_name : ""}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        : null
                                }
                            </TableBody>
                        </>
                        : null
                }
            </Table>
        </TableContainer>
    ):null
}

export default LiveTable