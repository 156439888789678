import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import userService from '../../../service/user.service';
import authService from '../../../service/auth.service';
import { ConfigContext } from '../../../ConfigContext';
import Loading from '../../Loading';
import Footer from "../../Footer";

const initialValues = {
  old_password: "",
  password: "",
  password2: "",
};

function ChangePassword2() {
  const navigate = useNavigate()
  const [values, setValues] = useState(initialValues);
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const configData = useContext(ConfigContext)
  const { t } = useTranslation();


  const [validations, setValidations] = useState({
    old_password: "",
    password: "",
    password2: "",
    refreshVal: "",
  });

  const {
    old_password: currentPW,
    password: newPW,
    password2: newPWConfirm,
    refreshVal,
  } = validations;

  const getUserInfo = async () => {
    try {
      const userInfo = await userService.getBalance();
      setUserId(userInfo.data.user.id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.changePassword({ values, userId });
      toast.success('The password modification has been completed successfully.', { theme: 'dark' })
      navigate('/')
    } catch (err) {
      console.log(err.response.data);
      setValidations({
        ...validations,
        old_password: err.response.data && err.response.data.old_password,
        password: err.response.data && err.response.data.password,
        password2: err.response.data && err.response.data.password2,
        refreshVal: err.response.data && err.response.data.detail,
      });
    }
  };

  useEffect(() => {
    const initialSetting = async () => {
      try {
        setIsLoading(true);
        await getUserInfo();
        if (userId) {
          setIsLoading(true);
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };
    initialSetting();
  }, [localStorage.getItem("user"), userId]);


  return (
  <div className="t1">
    <div className="page">
        <div className="page-content">
          <div className='profile-page2 layout2'>
            <div className="tab-menu-background-container" style={{ backgroundColor: configData ? configData.layout_card_bg : "#242424", }}>
              <div className="tab-menu-container d-flex" data-style="vertical" style={{ backgroundColor: configData ? configData.primary_color : "" }}>
                <a href="#" data-active="false" onClick={(e) => {
                  navigate("/profile")
                }}>
                  <img src={configData ? configData.side_menu_icon_11 : ""} alt="" style={{ filter: "brightness(0) invert(1)" }} />
                  {t("Profile")}
                </a>
                <a href="#" data-active="true" onClick={(e) => {
                  navigate("/change-password")
                }}>
                  <img src={configData ? configData.side_menu_icon_9 : ""} alt="" style={{ filter: "brightness(0) invert(1)" }} />
                  {t("Change_Password")}
                </a>
              </div>
            </div>
            <div className="standard-form-container" style={{ backgroundColor: configData ? configData.primary_color : "" }}>
              <div className="pswd_container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="standard-form-title" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "" }}>
                    {t("Change_Password")}
                    </div>
                    <form>
                      <div className="form-group mb-2">
                        <label style={{ color: configData ? configData.text_color : "#fff" }}>
                          {t("Current_password")}
                        </label>
                        <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="text" name="old_password" className="form-control" onChange={handleChange} />
                      </div>
                      {
                        validations &&
                        <span class="standard-required-message">{validations.old_password}</span>
                      }
                      <div className="form-group mb-2">
                        <label style={{ color: configData ? configData.text_color : "#fff" }}>
                          {t("New_password")} :
                        </label>
                        <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="password" name="password" className="form-control" onChange={handleChange} />
                      </div>
                      {
                        validations &&
                        <span class="standard-required-message">{validations.password}</span>
                      }
                      {/* <div className={`${newPW === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{newPW}</div> */}
                      <div className="form-group mb-2">
                        <label style={{ color: configData ? configData.text_color : "#fff" }}>
                        {t("Confirm")} {t("Password")}
                        </label>
                        <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="password" name="password2" className="form-control" onChange={handleChange} />
                      </div>
                      {
                        validations &&
                        <span class="standard-required-message">{validations.password2}</span>
                      }
                      {/* <div className={`${newPWConfirm === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{newPWConfirm}</div> */}
                      <div className="standard-button-group">
                        <input style={{ backgroundColor: configData ? configData.secondary_color : "#fff" }} type="button" className="standard-button" value={t("Change_Password")} onClick={handleSubmit} />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    {isLoading && <Loading />}
  </div>
  )
}

export default ChangePassword2