import React, { useContext } from "react";
import { Icon } from "@iconify/react";
import { ConfigContext } from "../../ConfigContext";

const MainGameItems = ({
  vertical_tab,
  gamelist,
  tabNum,
  isFeatured,
  StopProduct,
  handleProduct,
  startedGameList,
  isImgLoading,
  selectedGame,
  ezSelect
}) => {
  const configData = useContext(ConfigContext)

  return (
    <>
      {gamelist &&
        gamelist.map((game, i) => {
         const randomPercentage = Math.random() * (parseInt(configData ? configData.max_rtp : "") - parseInt(configData ? configData.min_rtp : "")) + parseInt(configData ? configData.min_rtp : "");

          return(
          <div
            className={`col-${
              vertical_tab ? (isFeatured ? "3" : "6") : isFeatured ? "3" : "4"
            } ps-0 pe-0`}
            style={
              vertical_tab && isFeatured
                ? { display: "flex", justifyContent: "center" }
                : {}
            }
            key={i}
            onClick={() => {
              if (game.id || game.gameid) {
                if (tabNum !== "easytech" && selectedGame === game.id) {
                  StopProduct(game.id);
                } else if (ezSelect && selectedGame === game.gameid) {
                  StopProduct(game.gameid);
                } else {
                  handleProduct(game, isFeatured);
                }
              }
            }}
          >
            <div className="position-relative">
              <div className="card card-style">
                <img
                  src={game.image_mobile}
                  className="img-fluid"
                  alt={game.name ? game.name : ""}
                  style={{ height: "auto", objectFit: "cover" }}
                />
                  {/* {
                  tabNum === "slots" && 
                <div className="rtp_rate_bar">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped"
                      style={{
                        width: `${randomPercentage}%`,
                        color: configData ? configData.text_color : "",
                        backgroundColor:
                          (randomPercentage >= 0) && (randomPercentage < 10)
                            ? configData && configData.rtp_1_color
                            : (randomPercentage >= 10) && (randomPercentage < 20)
                            ? configData && configData.rtp_2_color
                            : (randomPercentage >= 20) && (randomPercentage < 30)
                            ? configData && configData.rtp_3_color
                            : (randomPercentage >= 30) && (randomPercentage < 40)
                            ? configData && configData.rtp_4_color
                            : (randomPercentage >= 40) && (randomPercentage < 50)
                            ? configData && configData.rtp_5_color
                            : (randomPercentage >= 50) && (randomPercentage < 60)
                            ? configData && configData.rtp_6_color
                            : (randomPercentage >= 60) && (randomPercentage < 70)
                            ? configData && configData.rtp_7_color
                            : (randomPercentage >= 70) && (randomPercentage < 80)
                            ? configData && configData.rtp_8_color
                            : (randomPercentage >= 80) && (randomPercentage < 90)
                            ? configData && configData.rtp_9_color
                            : (randomPercentage >= 90) && (randomPercentage <= 100)
                            ? configData && configData.rtp_10_color
                            : "yellow",
                      }}
                    >
                      {`${randomPercentage.toFixed(0)}%`}
                    </div>
                  </div>
                </div>
                } */}
                

                {startedGameList
                  .map((item) => item.product_id)
                  .includes(game.id) && (
                  // <div className="playing-badge position-absolute" style={{fontSize:"11px"}}>{randomPercentage ? `Started ${randomPercentage.toFixed(0)}%`  : "Started"} </div>
                  <div className="playing-badge position-absolute" style={{fontSize:"11px"}}>{"Started"} </div>
                )}
              </div>
              {isImgLoading && (
                <div className="loading-image position-absolute">
                  <Icon icon="svg-spinners:bars-rotate-fade" width={40} />
                </div>
              )}
            </div>
          
          </div>
        )})}
    </>
  );
};

export default MainGameItems;
