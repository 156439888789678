import "./listItems.css";
import React, { useContext } from "react";
import { Icon } from "@iconify/react";
// import FrameImg from "../../image/frame.png";
import { motion } from "framer-motion/dist/framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImg from '../../images/placeholder_large_dark.jpg'
import { ConfigContext } from "../../ConfigContext";

const InAppGameItems = ({
  vertical_tab,
  gamelist,
  tabNum,
  StopProduct,
  handleProduct,
  startedGameList,
  isImgLoading,
  selectedGame,
  ezSelect,
}) => {

  const configData = useContext(ConfigContext)

  return (
    <div className="in-app-games-listing">
      {gamelist &&
        gamelist.map((game, i) => {
          const randomPercentage = Math.random() * (parseInt(configData ? configData.max_rtp : "") - parseInt(configData ? configData.min_rtp : "")) + parseInt(configData ? configData.min_rtp : "");
          return(
          <motion.div
            initial={{ scale: 0.6 }}
            animate={{ scale: startedGameList
                  .map((item) => item.gameid)
                  .includes(game.gameid) ? 1.1: .95 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className="in-app-game-item"
            key={i}
            onClick={() => {
              if (game.id || game.gameid) {
                if (tabNum !== "easytech" && selectedGame === game.id) {
                  StopProduct(game.id);
                } else if (ezSelect && selectedGame === game.gameid) {
                  StopProduct(game.gameid);
                } else {
                  handleProduct(game);
                }
              }
            }}
          >
            <div className="position-relative">
              <div className="card card-style m-1">
                <img src={configData?configData.Card_Frame:""} className="in-app-game-frame" alt=""/>
                <LazyLoadImage
                  src={game.thumbnail}
                  placeholderSrc={PlaceholderImg}
                  className="in-app-game-thumb"
                  alt={game.name ? game.name : ""}
                />
                </div>
                {startedGameList
                  .map((item) => item.gameid)
                  .includes(game.gameid) ? (
                    <div style={{
                        marginTop: "15px",
                        fontSize:"11px",
                        textAlign: "center",
                        backgroundColor: "orange",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "20px",
                    }}>
                      {randomPercentage ? `Started ${randomPercentage.toFixed(0)}%`  : "Started"} 
                    </div>
                ) : (
                  <>
                  <p style={{
                    color: configData ? configData.text_color : "",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: `${vertical_tab  ? "60px" : "100px"}`,
                    textAlign: "center",
                    fontWeight: "bold",
                    }}>{game.name ? game.name : ""}</p>
                  <div className="rtp_rate_bar">
                  <div className="progress" style={{borderRadius: "20px"}}>
                    <div
                      className="progress-bar progress-bar-striped"
                      style={{
                        width: `${randomPercentage}%`,
                        fontWeight: "bold",
                        color: "black",
                        backgroundColor:
                          (randomPercentage >= 0) && (randomPercentage < 10)
                            ? configData && configData.rtp_1_color
                            : (randomPercentage >= 10) && (randomPercentage < 20)
                            ? configData && configData.rtp_2_color
                            : (randomPercentage >= 20) && (randomPercentage < 30)
                            ? configData && configData.rtp_3_color
                            : (randomPercentage >= 30) && (randomPercentage < 40)
                            ? configData && configData.rtp_4_color
                            : (randomPercentage >= 40) && (randomPercentage < 50)
                            ? configData && configData.rtp_5_color
                            : (randomPercentage >= 50) && (randomPercentage < 60)
                            ? configData && configData.rtp_6_color
                            : (randomPercentage >= 60) && (randomPercentage < 70)
                            ? configData && configData.rtp_7_color
                            : (randomPercentage >= 70) && (randomPercentage < 80)
                            ? configData && configData.rtp_8_color
                            : (randomPercentage >= 80) && (randomPercentage < 90)
                            ? configData && configData.rtp_9_color
                            : (randomPercentage >= 90) && (randomPercentage <= 100)
                            ? configData && configData.rtp_10_color
                            : "yellow",
                      }}
                    >
                      {`${randomPercentage.toFixed(0)}%`}
                    </div>
                  </div>
                </div>
                </>
                )}
              </div>
              {isImgLoading && (
                <div className="loading-image position-absolute">
                  <Icon icon="svg-spinners:bars-rotate-fade" width={40} />
                </div>
              )}
          </motion.div>
        )})}
    </div>
  );
};

export default InAppGameItems;
