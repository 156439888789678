import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import authService from "../service/auth.service";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { Button } from "antd";
import tokenService from "../service/token.service";
import OTPSuccess from "../images/animation_success_otp.json";
import OTPFailed from "../images/animation_failed_otp.json";
import Countdown from "react-countdown";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";

const initialValues = {
  username: "",
};

function ForgetV2() {
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [password2Valid, setPassword2Valid] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(false);
  const [timer, setTimer] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpTime, setOtpTime] = useState();
  const [mobileNumber, setMobileNumber] = useState("")
  const [validations, setValidations] = useState({
    username: "",
    password: "",
    password2: "",
    non_field_errors: "",
    tac: "",
  });
  const [values, setValues] = useState(initialValues);
  const configData = useContext(ConfigContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const verifyCode = async (code) => {
    try {
      await authService.verifyTacV2({ username: values.username, code });
      setOtpVerified(true);
      // navigate('/signup3');
    } catch (err) {
      console.log(err);
      let tac_message;
      setOtpVerified(false);
      if (err.response && err.response.data && err.response.data.tac) {
        const username_msg = err.response.data.tac;
        if (username_msg.length > 0) {
          tac_message = username_msg[0];
        }
      }
      if (err.response && err.response.data && err.response.data.detail) {
        const username_msg = err.response.data.detail;
        tac_message = username_msg;
      }
      setValidations({
        ...validations,
        tac: tac_message,
      });
    }
  };

  const {
    username: nameVal,
    password: passVal,
    password2: pass2Val,
    cc: ccVal,
    mobile: mobileVal,
    non_field_errors: nonVal,
    referrer_code,
    tac: tacVal,
  } = validations;

  const requestOtp = async (e) => {
    e.preventDefault();
    setOtpLoading(true);

    let response;
    try {
      response = await authService.getForgetTacV2(values.username);
      if (
        response &&
        response.data &&
        response.data.recipient
      ) {
        setTimer(true);
        setOtpDisabled(true);
        setOtpTime(Date.now() + 60000);
        toast.success("TAC sent successfully!", { theme: "dark" });
      }
      // navigate("/signup3");
      setMobileNumber(response.data.recipient)
    } catch (err) {
      console.log("err => ", err, values);
      setTimer(false);
      setOtpDisabled(false);
      let username_message;
      let cc_message;
      let mobile_message;
      let password_message;
      let password2_message;
      let referrer_code_message;
      let non_field_errors_message;

      if (err.response && err.response.data && err.response.data.username) {
        username_message = err.response.data.username[0];
      }
      if (err.response && err.response.data && err.response.data.cc) {
        cc_message = err.response.data.cc;
      }
      if (err.response && err.response.data && err.response.data.mobile) {
        mobile_message = err.response.data.mobile;
      }
      if (err.response && err.response.data && err.response.data.password) {
        password_message = err.response.data.password;
      }
      if (err.response && err.response.data && err.response.data.password2) {
        password2_message = err.response.data.password2;
      }
      if (err.response && err.response.data && err.response.data.wait) {
        const wait = err.response.data.wait * 1000;
        setOtpTime(Date.now() + wait);
        setTimer(true);
        setOtpDisabled(true);
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.referrer_code
      ) {
        referrer_code_message = err.response.data.referrer_code;
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.non_field_errors
      ) {
        non_field_errors_message = err.response.data.non_field_errors[0];
        toast.error(non_field_errors_message, { theme: "dark" });
      }
      setValidations({
        ...validations,
        username: username_message,
        password: password_message,
        password2: password2_message,
        mobile: mobile_message,
        cc: cc_message,
        referrer_code: referrer_code_message,
        non_field_errors: non_field_errors_message,
      });
    }
    setOtpLoading(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setOtpLoading(true);
    setOtpDisabled(false);

    let response;
    try {
      const updatedValues = {
        ...values,
      };
      response = await authService.resetPasswordV2(values);

      if (
        response &&
        response.data &&
        response.data.id &&
        response.data.username
      ) {
        navigate("/signin");
        toast.success("Password Updated Successfully!", { theme: "dark" });
      }
    } catch (err) {
      console.log("err => ", err, values);
      setTimer(false);
      setOtpDisabled(false);
      let username_message;
      let cc_message;
      let mobile_message;
      let password_message;
      let password2_message;
      let referrer_code_message;
      let non_field_errors_message;

      if (err.response && err.response.data && err.response.data.username) {
        username_message = err.response.data.username[0];
      }
      if (err.response && err.response.data && err.response.data.cc) {
        cc_message = err.response.data.cc;
      }
      if (err.response && err.response.data && err.response.data.mobile) {
        mobile_message = err.response.data.mobile;
      }
      if (err.response && err.response.data && err.response.data.password) {
        password_message = err.response.data.password;
      }
      if (err.response && err.response.data && err.response.data.password2) {
        password2_message = err.response.data.password2;
      }
      if (err.response && err.response.data && err.response.data.wait) {
        const wait = err.response.data.wait * 1000;
        setOtpTime(Date.now() + wait);
        setTimer(true);
        setOtpDisabled(true);
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.referrer_code
      ) {
        referrer_code_message = err.response.data.referrer_code;
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.non_field_errors
      ) {
        non_field_errors_message = err.response.data.non_field_errors[0];
        toast.error(non_field_errors_message, { theme: "dark" });
      }
      setValidations({
        ...validations,
        username: username_message,
        password: password_message,
        password2: password2_message,
        mobile: mobile_message,
        cc: cc_message,
        referrer_code: referrer_code_message,
        non_field_errors: non_field_errors_message,
      });
    }
    setOtpLoading(false);
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setTimer(false);
      setOtpDisabled(false);
    } else {
      // Render a countdown
      setOtpDisabled(true);
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "tac" && e.target.value.length < 5) {
      setValidations({
        ...validations,
        tac: "",
      });
    }
    if (e.target.name === "username" && e.target.value.length <= 5) {
      setUsernameValid(false);
    } else {
      setUsernameValid(true);
    }
    if (e.target.name === "tac" && e.target.value.length === 5) {
      verifyCode(e.target.value);
    }
    if (e.target.name === "password" && e.target.value.length >= 6) {
      setPasswordValid(true);
    }
    if (e.target.name === "password2" && e.target.value.length >= 6 && (values.password === e.target.value)) {
      setPassword2Valid(true);
    }
    if (e.target.name === "tac" && e.target.value.length > 5) {
      return;
    }
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };
  const mobNumber = mobileNumber.slice(0, 4) + mobileNumber.slice(2).replace(/.(?=...)/g, 'X');

  return (
    <div className="t1">
      <div id="page">
        <div className="page-content">
          <div
            data-card-height="cover-full"
            className="background_class d-flex flex-column"
          >
            <div className="d-flex align-items-center justify-content-center mb-3">
              <img
                src={configData ? configData.forget_password_image : ""}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div style={{ zIndex: "2" }}>
              <div className="content">
                <div>
                  <h1
                    className="font-23 font-700"
                    style={{
                      color: configData ? configData.text_color_secondary : "",
                    }}
                  >
                    {t("Forget_Password")}?
                  </h1>
                </div>
                {/* <p
                  className="input-title my-3 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Don't_worry_it_happens")}
                </p> */}
                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Username")} :
                </p>
                <div
                  className={`input-style has-borders no-icon validate-field mb-4  ${values.user ? "input-style-active" : ""
                    }`}
                >
                  <input
                    name="username"
                    onChange={handleChange}
                    type="text"
                    disabled={otpVerified}
                    className={`form-control validate-name text-white`}
                    id="form1a"
                    value={values.username}
                    placeholder={t("Username")}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  <div
                    className={`${nameVal === "" ? "invalid-feedback" : "valid-feedback"
                      } `}
                  >
                    {nameVal}
                  </div>
                </div>
                {otpVerified ? (
                  <>
                    <p
                      className="input-title my-1 font-14"
                      style={{ color: configData ? configData.text_color : "" }}
                    >
                      {t("Password")} :
                    </p>
                    <div className="input-style has-borders no-icon validate-field mb-4">
                      <input
                        type="password"
                        name="password"
                        className="form-control validate-text text-white"
                        id="form3"
                        placeholder={t("Password")}
                        disabled={!otpVerified}
                        onChange={handleChange}
                      />
                      <i className="fa fa-times disabled invalid color-red-dark"></i>
                      <i className="fa fa-check disabled valid color-green-dark"></i>
                      <div
                        className={`${passVal === "" ? "valid-feedback" : "invalid-feedback"
                          } `}
                      >
                        {passVal}
                      </div>
                    </div>

                    <p
                      className="input-title my-1 font-14"
                      style={{ color: configData ? configData.text_color : "" }}
                    >
                      {t("Confirm")} {t("Password")} :
                    </p>

                    <div
                      className={`input-style has-borders no-icon validate-field mb-4  ${values.password2 ? "input-style-active" : ""
                        }`}
                    >
                      <input
                        type="password"
                        name="password2"
                        disabled={!otpVerified}
                        className={`form-control validate-password text-white`}
                        id="form1a"
                        placeholder={t("Confirm") + " " + t("Password")}
                        onChange={handleChange}
                      />
                      <i className="fa fa-times disabled invalid color-red-dark"></i>
                      <i className="fa fa-check disabled valid color-green-dark"></i>
                      <div
                        className={`${pass2Val === ""
                            ? "valid-feedback"
                            : "invalid-feedback"
                          } `}
                      >
                        {pass2Val}
                      </div>
                      <div
                        className={`${nonVal === "" ? "valid-feedback" : "invalid-feedback"
                          } `}
                      >
                        {nonVal}
                      </div>
                    </div>

                    <Button
                      loading={otpLoading}
                      disabled={!usernameValid || !passwordValid || !password2Valid}
                      data-back-button
                      className="btn mx-auto btn_green register_btn"
                      style={{
                        backgroundColor: configData
                          ? configData.secondary_color
                          : "",
                        height: "40px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                      onClick={handleResetPassword}
                    >
                      {t("Reset_Password")}
                    </Button>
                  </>
                ) : (
                  <div className="request-otp-box">
                    <div>
                      <div className={`input-style has-borders no-icon validate-field mb-4`}>
                        <div className="otp-input-box d-flex">
                          <input
                            type="text"
                            name="tac"
                            className={`form-control text-white`}
                            id="form1b"
                            placeholder={t("SMS_OTP")}
                            onChange={handleChange}
                            value={values.tac}
                          />
                        </div>
                        <div
                          className={`${tacVal === "" ? "valid-feedback" : "invalid-feedback"
                            } `}
                        >
                          {tacVal}
                        </div>
                      </div>
                      {timer && (
                        <p style={{ color: "white" }}>Sent To {mobNumber}</p>
                      )}
                    </div>

                    <div>
                      <Button
                        loading={otpLoading}
                        disabled={!usernameValid || otpDisabled}
                        data-back-button
                        className="btn btn_green register_btn d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: configData
                            ? configData.secondary_color
                            : "",
                          height: "40px",
                          fontWeight: "bold",
                        }}
                        onClick={requestOtp}
                      >
                        {t("REQUEST_OTP")}
                      </Button>
                      {timer && (
                        <div className="otp-timer-box d-flex" style={{ color: "white" }}>
                          <p style={{ color: "white" }} className="next-otp">Next OTP :&nbsp;</p>
                          <Countdown date={otpTime} renderer={renderer} />
                          <p style={{ color: "white" }} className="timer-unit">Sec</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <MenuNotification />
    </div>
  );
}

export default ForgetV2;
