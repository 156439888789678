import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ConfigContext } from "../../../ConfigContext";
import tokenService from "../../../service/token.service";
import authService from "../../../service/auth.service";
import SignUp3_2 from "../SignUp3/SignUp3_2";
import Captcha from "../../Captcha";
import Loading from "../../../components/Loading";

const initialValues = {
    username: "",
    password: "",
    password2: "",
    cc: "60",
    mobile: "",
    bank: "",
    account_number: "",
    account_name: "",
};

function LegacySignUp2({ setUserId, setAuth }) {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [values, setValues] = useState(initialValues);
    const [readOnly, setReadOnly] = useState(false);
    const [mobileValid, setMobileValid] = useState(false);
    const [valid, setValid] = useState(false)
    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    let rString = randomString(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    const [captcha, setCaptcha] = useState(rString)
    const [text, setText] = useState("")
    const configData = useContext(ConfigContext);
    const { t } = useTranslation();

    const [validations, setValidations] = useState({
        username: "",
        password: "",
        password2: "",
        cc: "",
        mobile: "",
        non_field_errors: "",
        referrer_code: "",
        tac: "",
        bank: "",
        account_name: "",
        account_number: "",
    });

    const handleChange = (e) => {
        setValues((values) => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const isInvalid = (updatedValues)=>{
        const verifyCaptcha = matchCaptcha();
        if (!verifyCaptcha) {
            return true
        }
        let validationFailed = false
        const currentValidations = {
            ...validations
        };

        ["username","password","mobile","bank","account_name","account_number","cc"].map(field=>{
            if(!updatedValues[field]){
                currentValidations[field] = "This is required field"
                validationFailed= true
            }else{
                currentValidations[field] = ""
            }
        })
        if (updatedValues.password !== updatedValues.password2){
            currentValidations.password2 = "Password and Confirm Password Dont match" 
            validationFailed = true
        } else {
            currentValidations.password2 = ""
        }
        if(validationFailed){
            setValidations({
                ...currentValidations
            })
        }
        return validationFailed
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let response;
        try {
            const updatedValues = {
                ...values,
            };
            const validation = isInvalid(updatedValues)
            if(validation){
                return
            }
            response = await authService.signupWithBank(updatedValues);
            if (
                response &&
                response.data &&
                response.data.id &&
                response.data.recipient
            ) {
                setUserId(response.data.id);
                tokenService.setRegisterUser({
                    ...updatedValues,
                    id: response.data.id,
                });
            }
            setLoading(true)
            await authService.login({ username: updatedValues.username, password: updatedValues.password });
            setLoading(false)
            navigate('/deposit');
            setAuth(true);
        } catch (err) {
            // console.log("err => ", err, values);
            let username_message;
            let cc_message;
            let mobile_message;
            let password_message;
            let password2_message;
            let referrer_code_message;
            let non_field_errors_message;
            let bank_message;
            let account_name_message
            let account_number_message

            if (err.response && err.response.data && err.response.data.username) {
                username_message = err.response.data.username[0]
            }
            if (err.response && err.response.data && err.response.data.cc) {
                cc_message = err.response.data.cc;
            }
            if (err.response && err.response.data && err.response.data.mobile) {
                mobile_message = err.response.data.mobile;
            }
            if (err.response && err.response.data && err.response.data.password) {
                password_message = err.response.data.password;
            }
            if (err.response && err.response.data && err.response.data.password2) {
                password2_message = err.response.data.password2;
            }
            if (err.response && err.response.data && err.response.data.bank) {
                bank_message = err.response.data.bank;
            }
            if (err.response && err.response.data && err.response.data.account_name) {
                account_name_message = err.response.data.account_name;
            }
            if (err.response && err.response.data && err.response.data.account_number) {
                account_number_message = err.response.data.account_number;
            }
            if (
                err.response &&
                err.response.data &&
                err.response.data.referrer_code
            ) {
                referrer_code_message = err.response.data.referrer_code;
            }
            if (
                err.response &&
                err.response.data &&
                err.response.data.non_field_errors
            ) {
                non_field_errors_message = err.response.data.non_field_errors[0];
                toast.error(non_field_errors_message, { theme: "dark" });
            }
            setValidations({
                ...validations,
                username: username_message,
                password: password_message,
                password2: password2_message,
                mobile: mobile_message,
                bank: bank_message,
                account_name: account_name_message,
                account_number: account_number_message,
                cc: cc_message,
                referrer_code: referrer_code_message,
                non_field_errors: non_field_errors_message,
            });
        }
    };
    const matchCaptcha = () => {
        if (text === captcha) {
            return true
        } else {
            setValid(true)
            return false
        }
    }


    const getReferralCode = () => {
        const searchParams = new URLSearchParams(location.search);
        const myParam = searchParams.get("referrer");
        const localStorageCode = localStorage.getItem("referralCode");
        if (myParam || localStorageCode) {
            setValues((values) => {
                return { ...values, referrer_code: myParam || localStorageCode };
            });
            localStorage.removeItem("referralCode");
            sessionStorage.setItem("from_refer", myParam || localStorageCode);
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
    };

    useEffect(() => {
        if (sessionStorage.getItem("from_refer")) {
            const ref_code = sessionStorage.getItem("from_refer");
            localStorage.setItem("referralCode", ref_code);
            sessionStorage.removeItem("from_refer");
            navigate(`/signup/?referrer=${ref_code}&openExternalBrowser=1`);
        }
        getReferralCode();
    }, []);

    useEffect(() => {
        const user = localStorage.getItem("registerUser");
        if (user) {
            const parsedUser = JSON.parse(user);
            if (parsedUser.id) {
                if (
                    String(parsedUser.mobile).length >= 8 &&
                    String(parsedUser.mobile).length <= 11
                ) 
                {
                    setMobileValid(true);
                }
                setValues({
                    ...values,
                    mobile: parsedUser.mobile,
                    cc: parsedUser.cc,
                    username: parsedUser.username
                });
                setUserId(parsedUser.id);
            }
        }
    }, [localStorage.getItem("registerUser")]);

    return (
        <div className='layout2'>
            <div className="login-panel standard-form-container">
                <div className="container">
                    <>
                        <div className="standard-form-title" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "" }}>
                            {t("Personal_Information")}
                        </div>
                        <div className="form-group mb-2">
                            <label style={{ color: configData ? configData.text_color : "#fff" }}>
                                {t("Username")}
                            </label>
                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="text" name="username" className="form-control" placeholder={t("Username")} onChange={handleChange} />
                            {
                                validations &&
                                <span class="standard-required-message">{validations.username}</span>
                            }
                        </div>
                        <div className="form-group mb-2">
                            <label style={{ color: configData ? configData.text_color : "#fff" }}>
                                {t("Mobile_No")}
                            </label>
                            <div>
                                <PhoneInput
                                    inputStyle={{
                                        width: "100%",
                                        borderRadius: "0px",
                                        border: "none",
                                        height: "40px",
                                        backgroundColor: configData ? configData.inputs_bg : "#000",
                                        color: configData ? configData.text_color : "#fff",
                                    }}
                                    containerStyle={{ marginTop: "0px", marginBottom: "10px", backgroundColor: configData ? configData.inputs_bg : "#000" }}
                                    buttonStyle={{ borderRadius: "0px", border: `1px solid ${configData ? configData.tertiary_color : ""}`, backgroundColor: configData ? configData.inputs_bg : "#000" }}
                                    country={configData ? (configData.phone_format) : "my"}
                                    onlyCountries={configData ? [(configData.phone_format)] : ["my"]}
                                    onChange={(phone, country) => {
                                        if (
                                            String(phone).length >= 8 &&
                                            String(phone).length <= 11
                                        ) {
                                            setMobileValid(true);
                                        }
                                        const reducedPhone = phone.replace(country.dialCode, "");
                                        setValues({
                                            ...values,
                                            cc: country.dialCode,
                                            mobile: reducedPhone,
                                        });
                                    }}
                                    className="has-borders text-white"
                                />
                            </div>
                            {
                                validations &&
                                <span class="standard-required-message">{validations.mobile}</span>
                            }
                        </div>

                        <div className="form-group mb-2 standard-password-field">
                            <label style={{ color: configData ? configData.text_color : "#fff" }}>
                                {t("Password")}
                            </label>
                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="password" name="password" className="form-control" placeholder={t("Password")} id="password_input" onChange={handleChange} />
                            <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                            {
                                validations &&
                                <span class="standard-required-message">{validations.password}</span>
                            }
                        </div>
                        <div className="form-group mb-2 standard-password-field">
                            <label style={{ color: configData ? configData.text_color : "#fff" }}>
                                {t("Confirm")} {t("Password")} :
                            </label>
                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="password" name="password2" className="form-control" placeholder={t("Confirm") + " " + t("Password")} id="password_input" onChange={handleChange} />
                            <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                            {
                                validations &&
                                <span class="standard-required-message">{validations.password2}</span>
                            }
                        </div>



                        <div className="standard-form-title mt-3" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "" }}>
                            {t("Payment_Information")}
                        </div>
                        <SignUp3_2 handleChange={handleChange} values={values} setValues={setValues} validations={validations} />
                        <div className="standard-form-title mt-3" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "" }}>
                            {t("Others")}
                        </div>
                        <div className="form-group mb-2 standard-password-field">
                            <label style={{ color: configData ? configData.text_color : "#fff" }}>
                                {t("Referral_Code")}
                            </label>
                            <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="text" name="referrer-code" className="form-control" placeholder={t("Referral_Code") + " (" + t("optional") + ")"} value={values.referrer_code} readOnly={readOnly} id="password_input" onChange={handleChange} />
                            <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                            {
                                validations &&
                                <span class="standard-required-message">{validations.referrer_code}</span>
                            }
                        </div>

                        <Captcha setCaptcha={setCaptcha} setText={setText} captcha={captcha} valid={valid} />


                        <div className="standard-button-group">
                            <input style={{ backgroundColor: configData ? configData.secondary_color : "#fff" }} type="button" className="standard-button" value={t("REGISTER")} onClick={handleSubmit} />
                        </div>

                    </>
                </div>

            </div>
            {isLoading && <Loading />}
        </div>
    );
}

export default LegacySignUp2;
