import React, { useContext, useEffect, useRef, useState } from "react";
import "./pages.css";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import Lottie from "lottie-react";
import { Spin } from "antd";
import { Icon } from "@iconify/react";
import BalanceWrapper from "../components/BalanceWrapper";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import MenuNotification from "../components/MenuNotification";
import Tablist from "../components/Tablist";
import Banner from "../components/Banner";
import Marquee from "react-fast-marquee";
import TopSlider from "../components/TopSlider";
import UserService from "../service/user.service";
import AuthService from "../service/auth.service";
import CashIcon from "../images/dollar.png";
import ChipIcon from "../images/casino-chips.png";
import { useNavigate } from "react-router";
import LiveAnimation from "../images/animation_lli1kx0e.json";
import { ConfigContext } from "../ConfigContext";
import TransitionsModal from "../components/common/TransitionsModal";
import { useTranslation } from "react-i18next";
import LiveTable from "../components/LiveTable";
import userService from "../service/user.service";
import DailyCheckin from "../components/DailyCheckin";
import PromoHorizontalList from "../components/PromoHorizontalList";
import CountdownTicker from "../components/common/CountdownTicker";
import CountdownTickerV2 from "../components/common/CountdownTickerV2";
import CountUpTicker from "../components/common/CountUpTicker";
import SkLoading from "../components/common/SkLoading";
import BalanceWrapperNew from "../components/BalanceWrapperNew";
import Footerlicense from "../components/Footerlicense";
import Auth3 from "../components/layout/auth-layout/Auth3";
import FooterContent from "../components/layout/FooterContent/FooterContent";
import LiveTable2 from "../components/layout/LiveTable/LiveTable2";
import BalanceSectionAuth4 from "../components/BalanceSectionAuth4";
import SublineFix from "../components/SublineFix";
{/* Site Name for demo purpose, will have to remove after demo */}
const SITE_NAME = process.env.REACT_APP_SITE_NAME;

const EASYTECH_GAME = process.env.REACT_APP_EASYTECH;
const JOKER_GAME = process.env.REACT_APP_JOKER;
const HABANERO_GAME = process.env.REACT_APP_HABANERO;
const PLAYTECH_GAME = process.env.REACT_APP_PLAYTECH;
const JDB_GAME = process.env.REACT_APP_JDB;
const SPADE_GAMING = process.env.REACT_APP_SPADE_GAMING;
const JILI_GAMING = process.env.REACT_APP_JILI;
const ASIA_GAMING = process.env.REACT_APP_ASIA_GAMING;
const LIVE22_GAMING = process.env.REACT_APP_LIVE22_GAMING;
const PRAGMATIC_GAMING = process.env.REACT_APP_PRAGMATIC_GAMING;
const VPOWER_GAMING = process.env.REACT_APP_VPOWER_GAMING;

function Home({ getWalletInfo, walletInfo }) {
  const { t } = useTranslation();
  const [tabNum, setTabNum] = useState("casino");
  const [images, setImages] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [newsfeeds, setNewsfeeds] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [gamelist, setGamelist] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [gameData, setGameData] = useState();
  const [isImgLoading] = useState(false);
  const [text, setText] = useState("");
  const [androidGameUrl, setAndroidGameUrl] = useState("");
  const [ios32GameUrl, setios32GameUrl] = useState("");
  const [ios64GameUrl, setios64GameUrl] = useState("");
  const [startedGameList, setStartedGameList] = useState([]);
  const [selectedGame, setSelectedGame] = useState();
  const [playModal, setPlayModal] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState("");
  const [notices, setNotices] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(0);
  const [gameError, setGameError] = useState(null);
  const [page, setPage] = useState(500);
  const [fetching, setFetching] = useState(false);
  const [isPage, setIsPage] = useState(true);
  const [ezSelect, setEzSelect] = useState(null);
  const [playText, setPlayText] = useState(t("Play_Now"));
  const [selectedProvider, setSelectedProvider] = useState("");
  const [startedGame, setStartedGame] = useState(null);
  const [checkinObject, setCheckinObject] = useState(null);
  const [productLoading, setProductLoading] = useState(false);
  const [number, setNumber] = useState(1064639.3);
  const [sliderLoading, setSliderLoading] = useState(false)
  const [skLoading, setSkLoading] = useState(true)
  const [fetchingGameId, setFetchingGameId] = useState(false)
  const [query, setQuery] = useState("")
  const [filteredGamelist, setFilteredGamelist] = useState([])
  const [search, setSearch] = useState(null)
  const configData = useContext(ConfigContext);
  const slotsTabRef = useRef();
  const navigate = useNavigate();

  var userAgent = navigator.userAgent.toLowerCase();

  const getSliderImgs = async () => {
    setLoading(true);
    setSliderLoading(true)
    setSkLoading(true)
    const sliderImages = await UserService.getSliderImages();
    let imgArr = [];
    if (sliderImages && sliderImages.data) {
      sliderImages.data
        .filter((sliderImage) => sliderImage.type === "static")
        .forEach((sliderImage) => {
          if (sliderImage.image_sm) {
            imgArr.push({ imgSrc: sliderImage.image_sm });
          }
        });
      setImages(imgArr);
      const popups = sliderImages.data.filter((notice) => {
        if (notice.type === "popup") {
          if (!notice.is_once) {
            let popup_isonce_onsite = JSON.parse(
              sessionStorage.getItem("popup_isonce_onsite")
            );
            if (!popup_isonce_onsite) {
              popup_isonce_onsite = [];
            }
            if (!popup_isonce_onsite.includes(notice.id)) {
              popup_isonce_onsite.push(notice.id);
              sessionStorage.setItem(
                "popup_isonce_onsite",
                JSON.stringify(popup_isonce_onsite)
              );
              return true;
            }
            // return true;
          }
          let popup_isonce = JSON.parse(localStorage.getItem("popup_isonce"));
          if (!popup_isonce) {
            popup_isonce = [];
          }
          if (!popup_isonce.includes(notice.id)) {
            popup_isonce.push(notice.id);
            localStorage.setItem("popup_isonce", JSON.stringify(popup_isonce));
            return true;
          }
          return false;
        } else {
          return false;
        }
      });
      setTimeout(() => {
        setNotices({
          popups,
        });
      }, 3000);
    }
    setTimeout(() => {
      setSliderLoading(false)
      setSkLoading(false)
    }, 1000)
    setLoading(false);
  };

  const getTabslist = async () => {
    setLoading(true);
    const tabslist = await UserService.getTablist();
    if (tabslist) {
      setTabs(tabslist.data);
    }
    setLoading(false);
  };

  const stopGames = async () => {
    setLoading(true);
    try {
      await UserService.stopGames();
      await getWalletInfo()
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  };

  const getNewsfeedsList = async () => {
    setLoading(true);
    setSkLoading(true)
    try {
      const newslist = await UserService.getNewsfeeds();

      setNewsfeeds(newslist && newslist.data ? newslist.data : []);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    setSkLoading(false)
  };

  // useEffect(() => {
  //   if (ezSelect && isPage) {
  //     getEasytechProductlist(page);
  //   }
  // }, [page, isPage]);

  // useEffect(() => {
  //   const baseLimit = 21;
  //   function handleScroll() {
  //     const { scrollTop, clientHeight, scrollHeight } =
  //       document.documentElement;

  //     if (scrollTop + clientHeight >= (scrollHeight / 2)) {
  //       // Bottom reached, trigger your action here
  //       if (!fetching) {
  //         setPage((pre) => pre + baseLimit);
  //       }
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [fetching]);

  const getProductlist = async () => {
    if (tabNum === "news") return;

    setProductLoading(true);
    try {
      const [products,featuredProducts] = await Promise.all([UserService.getProducts(tabNum),UserService.getFeaturedProducts()])
      setGamelist(products.data);
      setFeatured(featuredProducts.data);
    } catch (err) {
      console.log(err);
    }
    setTimeout(() => {
      setProductLoading(false);
    }, 1000)
  };

  const getEasytechProductlist = async (page, _game) => {
    setProductLoading(true);
    // setFetching(true);
    // if (page === 0) {
    //   setLoading(true);
    // }
    let game = selectedGame;
    if (_game) {
      game = _game;
    }
    try {
      let products;
      if (game.name === EASYTECH_GAME) {
        products = await UserService.getEztechProducts(0, page + 21);
      } else if (game.name === JOKER_GAME) {
        products = await UserService.getJokerProducts(0, page + 21);
      } else if (game.name === HABANERO_GAME) {
        products = await UserService.getHabaneroProducts(0, page + 21);
      } else if (game.name === PLAYTECH_GAME) {
        products = await UserService.getPlaytechProducts(0, page + 21);
      } else if (game.name === JDB_GAME) {
        products = await UserService.getJDBProducts(0, page + 21);
      } else if (game.name === SPADE_GAMING) {
        products = await UserService.getSpadeGamingProducts(0, page + 21);
      } else if (game.name === JILI_GAMING) {
        products = await UserService.getJiliProducts(0, page + 21);
      } else if (game.name === ASIA_GAMING) {
        products = await UserService.getAsiaGamingProducts(0, page + 21);
      } else if (game.name === LIVE22_GAMING) {
        products = await UserService.getLive22Products(0, page + 21);
      } else if (game.name === PRAGMATIC_GAMING) {
        products = await UserService.getPragmaticProducts(0, page + 21);
      } else if (game.name === VPOWER_GAMING) {
        products = await UserService.getVpowerProducts(0, page + 21);
      }
      setSearch({
        show: true,
        name: game.name
      });
      let data = products.data;

      if (
        game.name === HABANERO_GAME ||
        game.name === JDB_GAME ||
        game.name === JILI_GAMING ||
        game.name === JOKER_GAME ||
        game.name === LIVE22_GAMING ||
        game.name === PLAYTECH_GAME ||
        game.name === PRAGMATIC_GAMING ||
        game.name === SPADE_GAMING ||
        game.name === VPOWER_GAMING
      ) {
        data = data.map((item) => {
          return {
            gameid: String(item.c_h5_code),
            id: game.id,
            credit_type: item.credit_type,
            name: item.c_name,
            provider: item.c_prod_name,
            thumbnail: item.c_image,
            title: item.c_name,
            category: item.c_category
          };
        });
      }
      // if (game.name === JILI_GAMING) {
      //   data = data.map((item) => {
      //     return {
      //       gameid: String(item.GameId),
      //       id: item.GameId,
      //       credit_type: item.credit_type,
      //       name: item.name["en-US"],
      //       provider: game.name,
      //       thumbnail: item.image,
      //       title: item.name["en-US"],
      //     };
      //   });
      // }

      if (data.length === 0) {
        setIsPage(false);
      }
      if (data.length !== 0) {
        setIsPage(true);
        if (game.name !== EASYTECH_GAME) {
          setSelectedProvider(data[0].provider);
        }
        if (game.name === EASYTECH_GAME) {
          setSelectedGame({
            ...game,
            credit_type: data[0].credit_type,
          });
        }
      }
      if (page === 0) {
        setGamelist(data);
      } else {
        setGamelist(data);
      }
    } catch (err) {
      console.log(err);
    }
    // setTimeout(() => {
    //   setFetching(false);
    // }, 800);
    setTimeout(() => {
      setProductLoading(false);
    }, 1000)
  };

  const getGameStartedList = async (game_name) => {
    try {
      const userInfo = await UserService.getBalance();
      const result = await UserService.getStartedGames(userInfo.data.user.id);
      const new_game = result.data.filter((item) => item.login == game_name)
      if (new_game && new_game.length) {
        setGameData(prevGameData=>({
          ...prevGameData,
          started_id: new_game[0].id
        }))
      }
      setStartedGameList(result.data);

      if (result.data.length === 0) {
        localStorage.setItem("startedGame", null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleProduct = async (game,isFeatured) => {
    const localUser = localStorage.getItem("user");
    if (!localUser) {
      return navigate("/signin");
    }
    if (
      game.name === EASYTECH_GAME ||
      game.name === JOKER_GAME ||
      game.name === HABANERO_GAME ||
      game.name === PLAYTECH_GAME ||
      game.name === JDB_GAME ||
      game.name === SPADE_GAMING ||
      game.name === JILI_GAMING ||
      game.name === LIVE22_GAMING ||
      game.name === PRAGMATIC_GAMING ||
      game.name === VPOWER_GAMING
    ) {
      if(isFeatured){
        try {
          slotsTabRef.current.click()
        } catch (error) {
          console.log(error)
        }
      }
      if (startedGameList.map((item) => item.product_id).includes(game.id)) {
        return await StopProduct(game.id);
      }
      setPlayText("Start");
      setPlayModal(true);
    } else {
      if (startedGameList.map((item) => item.gameid).includes(game.gameid)) {
        return await StopProduct(game.id);
      }
      setPlayText("Play Now");
      if (ezSelect && !isFeatured) {
        setPlayModal(false);
        initiateProduct("h5", game.gameid, isFeatured);
      } else {
        setPlayModal(true);
      }
    }
    setSelectedGame(game);
    if (game.credit_type === "CC") {
      if (walletInfo.balance > 0) {
        setSelectedCredit("CA");
      } else if (walletInfo.chips_balance > 0) {
        setSelectedCredit("CH");
      }
    } else if (game.credit_type === "CA") {
      setSelectedCredit("CA");
    } else if (game.credit_type === "CH") {
      setSelectedCredit("CH");
    }
  };

  const handleGameLaunch = async () => {
    if (
      tabNum === "slots" &&
      (selectedGame.name === EASYTECH_GAME ||
        selectedGame.name === JOKER_GAME ||
        selectedGame.name === HABANERO_GAME ||
        selectedGame.name === PLAYTECH_GAME ||
        selectedGame.name === JDB_GAME ||
        selectedGame.name === SPADE_GAMING ||
        selectedGame.name === JILI_GAMING ||
        selectedGame.name === LIVE22_GAMING ||
        selectedGame.name === PRAGMATIC_GAMING ||
        selectedGame.name === VPOWER_GAMING)
    ) {
      getEasytechProductlist(500);
      setTimeout(() => {
        setEzSelect(selectedGame);
      }, 500);
    } else {
      const { ltype, id } = selectedGame;
      initiateProduct(ltype, id);
    }
    setPlayModal(false);
  };

  const initiateProduct = async (ltype, id,isFeatured) => {
    let selectedGameLocal = selectedGame
    if (startedGameList.map((item) => item.product_id).includes(id)) {
      await StopProduct(id);
    }
    setLoading(true);
    try {
      if (ltype === "h5") {
        let win;
        if(configData && !configData.game_launch_in_site) {
          win = window.open("about:blank");
        }
        let res;
        if (ezSelect && !isFeatured) {
          if (ezSelect.name === EASYTECH_GAME) {
            res = await UserService.startEasytechProduct(id, selectedCredit);
          } else if (
            ezSelect.name === JOKER_GAME ||
            ezSelect.name === HABANERO_GAME ||
            ezSelect.name === PLAYTECH_GAME ||
            ezSelect.name === JDB_GAME ||
            ezSelect.name === SPADE_GAMING ||
            ezSelect.name === JILI_GAMING ||
            ezSelect.name === LIVE22_GAMING ||
            ezSelect.name === PRAGMATIC_GAMING ||
            ezSelect.name === VPOWER_GAMING
          ) {
            res = await UserService.startEasytogoProduct(
              ezSelect.id,
              id,
              selectedCredit
            );
          }
        } else {
          res = await UserService.startProduct(id, selectedCredit);
        }

        if(configData && configData.game_launch_in_site) {
          // Stay in our site
          let resSrc;
          res && res.data.url.url ? resSrc = res.data.url.url : resSrc = res.data.url;
          if (resSrc) {
            navigate('/games', { state: { gameSrc: resSrc, gameId: id  } });
          }
        } else {
          // Redirect new windows
          if (res && res.data.url.url) {
            win.location = res.data.url.url;
          } else {
            win.location = res.data.url;
          }
        }

        setSelectedGame(id);
        await getWalletInfo();
      }
      let username=""
      if (ltype === "app") {
        const res = await UserService.startProduct(id, selectedCredit);
        username=res.data.username
        setSelectedGame(id);
        if (selectedGameLocal.module != "easytogo123") {
          setGameData({ ...res.data, customUrl:{
            android:selectedGameLocal.android_dl_link,
            ios:selectedGameLocal.ios_dl_link
          } });
        } else {
          setGameData({...res.data});
        }
        if (res.data.name === "Pussy") {
          setAndroidGameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Kiss918") {
          setAndroidGameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Mega") {
          setAndroidGameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Evo888") {
          setAndroidGameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Candy") {
          setAndroidGameUrl(
            `candy888android://lobbycandyandroid?account=${res.data.username}&password=${res.data.password}`
          );
        }
        setOpen(true);
        setGameError(null);
        await getWalletInfo();
      }
      await getGameStartedList(username);
      username=""
    } catch (err) {
      console.log(err);
      if (err.response && err.response.data && err.response.data.detail) {
        setGameError({ msg: err.response.data.detail });
      }
    }
    setLoading(false);
  };

  const StopProduct = async (id) => {
    setLoading(true);
    try {
      await UserService.stopProduct(id);
      await getWalletInfo();
      await getGameStartedList();
      setGameError(null);
    } catch (err) {
      console.log(err);
      setGameError({ msg: err.message });
    }
    setLoading(false);
  };

  const copy = async (copyText) => {
    if (copyText !== "") {
      await navigator.clipboard.writeText(copyText);
      setText(copyText);
    }
  };

  const handleCloseNotice = () => {
    setCurrentPopup((prevIndex) => prevIndex + 1);
  };

  const setupStartedGame = (games, startedGames) => {
    startedGames.map((sGame) => {
      games.map((game) => {
        if (sGame.gameid === game.gameid) {
          const startedGame = JSON.stringify(game);
          localStorage.setItem("startedGame", startedGame);
        }
      });
    });
  };

  const fetchDailyCheckins = async () => {
    setSkLoading(true)
    try {
      const { data } = await userService.getDailyCheckins();
      if (!data) {
        setCheckinObject(null);
      }
      setCheckinObject(data);
    } catch (err) {
      console.log(err);
    }
    setSkLoading(false)
  };

  useEffect(() => {
    getSliderImgs();
    getTabslist();
    getNewsfeedsList();
    stopGames()

    const handleOnLoad = (e) => {
      sessionStorage.removeItem("popup_isonce_onsite");
    };
    window.addEventListener("load", handleOnLoad);

    return () => {
      window.removeEventListener("load", handleOnLoad);
    };
  }, []);

  useEffect(() => {
    if (text === "") {
      setText("");
    }
  }, [text]);

  useEffect(() => {
    setGamelist([]);
    if (tabNum === "easytech") {
      const game = {
        urltype: "EZTECH88",
        name: "Easytech88",
        thumbnail: configData &&
        configData.category_tab_1_type === "easytech" ? (
        configData.category_tab_1_icon
        ) : configData.category_tab_2_type === "easytech" ? (
        configData.category_tab_2_icon
        ) : configData.category_tab_3_type === "easytech" ? (
          configData.category_tab_3_icon
        ) : configData.category_tab_4_type  === "easytech" ? (
          configData.category_tab_4_icon
        ) : "",
      };
      setSelectedGame(game);
      setSelectedProvider("Easytech");
      setEzSelect(game);
      handleProduct(game);
      setTimeout(() => {
        getEasytechProductlist(500, game);
      }, 500);
    } else {
      getProductlist();
      setPage(0);
      setEzSelect(null);
      setSelectedProvider("");
    }
    getGameStartedList();
    setIsPage(true);
  }, [tabNum]);

  useEffect(() => {
    if (gamelist.length !== 0 && startedGameList.length !== 0) {
      setupStartedGame(gamelist, startedGameList);
    }
    if (!startedGameList.length) {
      setQuery("")
   }
      }, [gamelist.length, startedGameList.length]);

  useEffect(() => {
    const sGame = localStorage.getItem("startedGame");
    if (sGame) {
      const parsedGame = JSON.parse(sGame);
      setStartedGame(parsedGame);
    }
  }, [localStorage.getItem("startedGame")]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      fetchDailyCheckins();
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    //* Fallback timeout if api err
    setTimeout(() => {
      setSkLoading(false)
    }, 5000)
  }, [])

  const resetSlots = () => {
    if (tabNum === "slots") {
      setPage(0);
      setEzSelect(null);
      getGameStartedList();
      setSelectedProvider("");
      setGamelist([]);
      getProductlist();
      setSearch({
        show: false
      })
    }
  };

  // Simulate changing the number after a delay
  // useEffect(() => {
  //   let currentValue = 1064639.32; // Starting value
  //   const getRandomIncrement = (min, max) => {
  //     return Math.random() * (max - min) + min;
  //   };
  //   const timeout = setInterval(() => {
  //     currentValue += getRandomIncrement(0.01, 20);
  //     setNumber(currentValue.toFixed(2));
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, []);

  const searchGames = async () => {
    if (query) {
      const newGameList = await UserService.searchGames(query, search.name)
      let data = newGameList.data
      if (data) {
        data = data.map((item) => {
          return {
            gameid: String(item.c_h5_code),
            id: selectedGame.id,
            credit_type: item.credit_type,
            name: item.c_name,
            provider: item.c_prod_name,
            thumbnail: item.c_image,
            title: item.c_name,
            category: item.c_category,
          };
        });
        setFilteredGamelist(data)
      }
    } else {
      setFilteredGamelist([])
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      searchGames()
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [query])

  const changeGameId = async (gameId) => {
    try {
      setFetchingGameId(true)
      const result = await UserService.changeGameId(gameId);
      setGameData(prevGameData=>({
        ...prevGameData,
        username: result.data.login,
        password: result.data.password,
        started_id: gameId+1
      }))
      setFetchingGameId(false)
    } catch (error) {
      setFetchingGameId(false)
    }
    // const stGames = await UserService.getStartedGames(userInfo.data.user.id);
  }
  return (
    // <div className="t1" style={{ backgroundImage: `url(${backgroundImg})` }}>
    <div className="t1">
    {configData && configData.sub_line_fix && <SublineFix />}
    {skLoading ?
      <SkLoading currentUser={currentUser} />:
      <div className="page">
        <div className="page-content home-page-content footer-clear">
          <div
            className="noticebar"
            style={{
              // height: "30px",
              backgroundImage: `url(${
                configData ? configData.marquee_bg : ""
              })`,
            }}
          >
            <Marquee style={{height: `${configData ? configData.marquee_height : 10}px`}}>
              {newsfeeds.map((feed, newsindex) => (
                <span
                  key={newsindex}
                  style={{ color: configData ? configData.marquee_text_color : "" }}
                >
                  {feed.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ))}
            </Marquee>
          </div>
          <TopSlider images={images} />
          {configData && configData.payment_banner ? (
          <div className="payment_banner">
            <img
              src={configData ? configData.payment_banner : ""}
              style={{ width: "100%" }}
              alt="Accept Payment"
            />
          </div>
          ) : ""}
          {
            (configData && (configData.member_auth_style == "1")) || (configData && !configData.member_auth_style)?
            <BalanceWrapper
              currentUser={currentUser}
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
              StopProduct={StopProduct}
            />:null
          }
          {
            configData && (configData.member_auth_style == "2")?
            <BalanceWrapperNew
              currentUser={currentUser}
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
              StopProduct={StopProduct}
            />
            :null
          }
          {
            configData && (configData.member_auth_style == "3")?
            <Auth3
              currentUser={currentUser}
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
              StopProduct={StopProduct}
            />
            :null
          }
          {
            configData && (configData.member_auth_style == "4")?
              <BalanceSectionAuth4 
                currentUser={currentUser}
                getWalletInfo={getWalletInfo}
                walletInfo={walletInfo}
                StopProduct={StopProduct}
              />
            :null
          }
          {configData && configData.jackpot && (configData.jackpot_style == "1" ?
          <CountdownTicker />
          : configData.jackpot_style == "2" ?
          <CountdownTickerV2 />
          :null
          )}
          {/* Demo purpose  */}
          {(!currentUser && SITE_NAME === "NNNN8888") && (
            <div>
              <div className="login-container w-full">
                <div className="left-column">
                  <div className="login-btn" onClick={() => navigate("/signin")}>
                    <img
                      src={configData?configData.home_login_btn :""}
                      className="login-image"
                      alt=""
                    />
                  </div>
                </div>
                <div className="right-column">
                  <div className="register-btn" onClick={() => navigate("/signup")}>
                    <img
                      style={{marginLeft: "5px"}}
                      src={configData?configData.home_register_btn :""}
                      className="register-image"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {configData && configData.daily_checkin && (configData.checkin_appearance === "home") && checkinObject && <DailyCheckin checkin={checkinObject} fetchDailyCheckins={fetchDailyCheckins}/>}
          {currentUser && configData && configData.live_table ? (
              configData && configData.live_table_style == "1" ? (
                <LiveTable />
              ) : configData && configData.live_table_style == "2" ? (
                <LiveTable2 />
              ) : (
                <LiveTable />
              )
            ) : null}
          {
            configData && configData.banner2?
            <div className="p-2 eci88_banner position-relative">
              <img src={configData.banner2} alt="" style={{ width: "100%", borderRadius: "10px" }} />
            </div>
            :null
          }
          {configData && configData.promo_rewards && <PromoHorizontalList />}
          <Banner currentUser={currentUser} />

          <Tablist
            isFeatured={true}
            tablists={tabs}
            setTabNum={setTabNum}
            tabNum={tabNum}
            gamelist={featured}
            isImgLoading={isImgLoading}
            selectedGame={selectedGame}
            handleProduct={handleProduct}
            StopProduct={StopProduct}
            startedGameList={startedGameList}
            setPage={setPage}
            provider={selectedProvider}
            resetSlots={resetSlots}
            productLoading={productLoading}
          />
                    <Tablist
            tablists={tabs}
            slotsTabRef={slotsTabRef}
            setTabNum={setTabNum}
            tabNum={tabNum}
            gamelist={!query?gamelist:filteredGamelist}
            setQuery={setQuery}
            query={query}
            search={search}
            setSearch={setSearch}
            isImgLoading={isImgLoading}
            selectedGame={selectedGame}
            handleProduct={handleProduct}
            StopProduct={StopProduct}
            startedGameList={startedGameList}
            setPage={setPage}
            ezSelect={ezSelect}
            provider={selectedProvider}
            resetSlots={resetSlots}
            productLoading={productLoading}
                      />
          {fetching && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin
                size="large"
                style={{ color: configData ? configData.text_color : "" }}
              />
            </div>
          )}
          <Footerlicense/>
          {
            configData && configData.footer_content_enabled?
            <FooterContent />
            :null
          }
          <Footer />
        </div>
        {isLoading && <Loading />}

        <MenuNotification />
        {isOpen && (
          <TransitionsModal open={true} onClose={() => setOpen(false)}>
            <div className="download-app-info">
              <div className="balance-wrap">
                {t("Balance")} {t("In_Game")}:{" "}
                {gameData.credit ? gameData.credit : 0.0}
              </div>
              <div className="accountInfo-wrap">
                <div className="d-flex justify-between my-10">
                  <div className="d-flex flex-col justify-center text-12 font-bold text-uppercase">
                    {t("Username")}:
                  </div>
                  <div className="clipboard-wrap d-flex justify-between bg-silver">
                    <div>{gameData.username}</div>
                    <div onClick={() => copy(gameData.username)}>
                      {text === gameData.username ? (
                        <Icon
                          icon="material-symbols:check"
                          width={20}
                          color="green"
                        />
                      ) : (
                        <Icon icon="bxs:copy" width={20} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-between">
                  <div className="d-flex flex-col justify-center text-12 font-bold text-uppercase">
                    {t("Password")}:
                  </div>
                  <div className="clipboard-wrap d-flex justify-between bg-silver">
                    <div>{gameData.password}</div>
                    <div onClick={() => copy(gameData.password)}>
                      {text === gameData.password ? (
                        <Icon
                          icon="material-symbols:check"
                          width={20}
                          color="green"
                        />
                      ) : (
                        <Icon icon="bxs:copy" width={20} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-between w-full">
                  {gameData.customUrl?
                    <a
                      href={`${gameData.customUrl.android}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="download-apk-btn d-flex">
                        <div className="d-flex flex-col justify-center">
                          {t("Android")} {t("Download")}
                        </div>
                        <div className="d-flex flex-col justify-center">
                          <Icon icon="uil:android" width={20} />
                        </div>
                      </div>
                    </a>
                  :gameData.url.android.length > 0 &&
                  gameData.url.android[0].includes("https://") ? (
                    <a
                      href={`${gameData.url.android}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="download-apk-btn d-flex">
                        <div className="d-flex flex-col justify-center">
                          {t("Android")} {t("Download")}
                        </div>
                        <div className="d-flex flex-col justify-center">
                          <Icon icon="uil:android" width={20} />
                        </div>
                      </div>
                    </a>
                  ) : (
                    <a
                      href={`https://${gameData.url.android}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="download-apk-btn d-flex">
                        <div className="d-flex flex-col justify-center">
                          {t("Android")} {t("Download")}
                        </div>
                        <div className="d-flex flex-col justify-center">
                          <Icon icon="uil:android" width={20} />
                        </div>
                      </div>
                    </a>
                  )}

                  {gameData.customUrl?
                    <a
                      href={`${gameData.customUrl.ios}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="download-apk-btn d-flex">
                        <div className="d-flex flex-col justify-center">
                          {t("iOS")} {t("Download")}
                        </div>
                        <div className="d-flex flex-col justify-center">
                          <Icon icon="ic:baseline-apple" width={20} />
                        </div>
                      </div>
                    </a>
                  :gameData.url.ios.length > 0 &&
                  gameData.url.ios[0].includes("https://") ? (
                    <a
                      href={`${gameData.url.ios}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="download-apk-btn d-flex">
                        <div className="d-flex flex-col justify-center">
                          {t("iOS")} {t("Download")}
                        </div>
                        <div className="d-flex flex-col justify-center">
                          <Icon icon="ic:baseline-apple" width={20} />
                        </div>
                      </div>
                    </a>
                  ) : (
                    <a
                      href={`https://${gameData.url.ios}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="download-apk-btn d-flex">
                        <div className="d-flex flex-col justify-center">
                          {t("iOS")} {t("Download")}
                        </div>
                        <div className="d-flex flex-col justify-center">
                          <Icon icon="ic:baseline-apple" width={20} />
                        </div>
                      </div>
                    </a>
                  )}
                </div>
                {userAgent.indexOf("android") > -1 ? (
                  <div className="d-flex justify-between w-full" style={{gap: "5px"}}>
                    <a
                      className="w-full"
                      href={androidGameUrl ? androidGameUrl : "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="play-btn">
                        <div className="d-flex flex-col justify-center">
                          {t("Play_Now")}
                        </div>
                      </div>
                    </a>
                    {
                      configData && configData.change_game_id &&
                      <a
                        className="w-full"
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e)=>{
                          e.preventDefault();
                          if (!fetchingGameId) {
                            changeGameId(gameData.started_id)}
                          }
                        }
                      >
                        <div className="play-btn">
                          <div className="d-flex flex-col justify-center">
                            {!fetchingGameId?(t("Change_Game_Id")):"fetching..."}
                          </div>
                        </div>

                      </a>
                    }
                  </div>
                ) : (
                  <>
                    {userAgent.indexOf("iphone") > -1 &&
                    window.innerWidth <= 320 ? (
                      <div className="d-flex justify-between w-full" style={{gap: "5px"}}>
                        <a
                          className="w-full"
                          href={ios32GameUrl ? ios32GameUrl : "/"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="play-btn">
                            <div className="d-flex flex-col justify-center">
                              {t("Play_Now")}
                            </div>
                          </div>
                        </a>
                        {
                          configData && configData.change_game_id &&
                          <a
                            className="w-full"
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e)=>{
                              e.preventDefault();
                              if (!fetchingGameId) {
                                changeGameId(gameData.started_id)}
                              }
                            }
                          >
                            <div className="play-btn">
                              <div className="d-flex flex-col justify-center">
                                {!fetchingGameId?(t("Change_Game_Id")):"fetching..."}
                              </div>
                            </div>

                          </a>
                        }
                      </div>
                    ) : (
                      <div className="d-flex justify-between w-full" style={{gap: "5px"}}>
                        <a
                          className="w-full"
                          href={ios64GameUrl ? ios64GameUrl : "/"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="play-btn">
                            <div className="d-flex flex-col justify-center">
                              {t("Play_Now")}
                            </div>
                          </div>

                        </a>
                        {
                          configData && configData.change_game_id &&
                          <a
                            className="w-full"
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e)=>{
                              e.preventDefault();
                              if (!fetchingGameId) {
                                changeGameId(gameData.started_id)}
                              }
                            }
                          >
                            <div className="play-btn">
                              <div className="d-flex flex-col justify-center">
                                {!fetchingGameId?(t("Change_Game_Id")):"fetching..."}
                              </div>
                            </div>

                          </a>
                        }
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </TransitionsModal>
        )}
        {selectedGame && (
          <TransitionsModal
            title={selectedGame.name}
            open={playModal}
            onClose={() => setPlayModal(false)}
            style={{
              textAlign: "center",
              borderRadius: "15px",
              padding: "1.5rem 1rem",
            }}
          >
            <div>
              <div className="flex flex-row justify-center">
                <img
                  src={
                    selectedGame.image_mobile
                      ? selectedGame.image_mobile
                      : selectedGame.thumbnail
                  }
                  alt={selectedGame.name}
                  style={{ width: "150px", borderRadius: "1rem" }}
                />
              </div>
              <p className="text-md font-medium mt-3">
                {t("Note")}: 
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {t("The_Game")} {t("Supports")}{" "}
                  {selectedGame.credit_type === "CA" && "only CASH. "}
                  {selectedGame.credit_type === "CH" && "only CHIPS. "}
                  {selectedGame.credit_type === "CC" &&
                  "Cash and Chips both types. "}
                </span>
                {"Please_select_an_option"}.
              </p>
              <div
                style={{ display: "flex" }}
                className="flex flex-row justify-center items-center my-3 gap-x-3"
              >
                {(selectedGame.credit_type === "CC" ||
                  selectedGame.credit_type === "CA") && (
                  <div
                    onClick={() => setSelectedCredit("CA")}
                    className="d-flex items-center justify-center gap-2
            balance-account w-1/2 p-3"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "1rem",
                      width: "50%",
                      borderColor:
                        selectedCredit === "CA" ? "rgb(92, 149, 224)" : "#ccc",
                      background:
                        selectedCredit === "CA"
                          ? "rgba(92, 149, 224, .2)"
                          : "transparent",
                    }}
                  >
                    <img className="img-icon" src={CashIcon} alt="" />
                    <div
                      className={`d-flex flex-col items-end text-stone-900`}
                      style={{ fontWeight: "bold" }}
                    >
                      <h6>{t("Cash")}</h6>
                      <h6>{currentUser ? walletInfo.balance : "0.00"}</h6>
                    </div>
                  </div>
                )}
                {(selectedGame.credit_type === "CC" ||
                  selectedGame.credit_type === "CH") && (
                  <div
                    onClick={() => setSelectedCredit("CH")}
                    className="d-flex items-center justify-center gap-2 balance-account w-1/2 p-3"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "1rem",
                      width: "50%",
                      borderColor:
                        selectedCredit === "CH" ? "rgb(92, 149, 224)" : "#ccc",
                      background:
                        selectedCredit === "CH"
                          ? "rgba(92, 149, 224, .2)"
                          : "transparent",
                    }}
                  >
                    <img className="img-icon" src={ChipIcon} alt="" />
                    <div
                      className={`d-flex flex-col items-end text-stone-900`}
                      style={{ fontWeight: "bold" }}
                    >
                      <h6>{t("Chips")}</h6>
                      <h6>{currentUser ? walletInfo.chips_balance : "0.00"}</h6>
                    </div>
                  </div>
                )}
              </div>
              <button
                onClick={handleGameLaunch}
                className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
            font-700 w-100 btn-s mt-2 h-12`}
              >
                {playText}
              </button>
            </div>
          </TransitionsModal>
        )}
        {notices &&
        notices.popups &&
        notices.popups.length &&
        notices.popups[currentPopup] ? (
          <TransitionsModal
            open={notices.popups.length?true:false}
            onClose={() => handleCloseNotice()}
            style={{ overflow: "hidden" }}
          >
            <img
              style={{ width: "100%" }}
              src={notices.popups[currentPopup].image_sm}
              alt=""
            />
          </TransitionsModal>
        ) : null}

        <TransitionsModal
          title={selectedGame ? selectedGame.name : "Game Error"}
          open={gameError?true:false}
          onClose={() => setGameError(false)}
          style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
        >
          <div>
            <div className="flex flex-row justify-center">
              <Icon icon="codicon:error" color="#f27474" width={70} />
              {/* <img
                src={selectedGame.image_mobile}
                alt={selectedGame.name}
                style={{ width: "150px", borderRadius: "1rem" }}
              /> */}
            </div>
            <p className="text-md font-medium mt-3">
              {gameError && gameError.msg ? gameError.msg : null}
            </p>
            <button
              onClick={() => setGameError(false)}
              className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
            font-700 w-100 btn-l mt-3 h-12`}
            >
              {t("OK")}
            </button>
          </div>
        </TransitionsModal>

        <AnimatePresence>
          {!ezSelect && startedGame && (
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: 150 }}
              transition={{ type: "spring", stiffness: 100, damping: 10 }}
              className="started-game-container"
              style={{
                position: "fixed",
                bottom: 58,
                left: 0,
                width: "100%",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                zIndex: 100,
                padding: "8px",
              }}
            >
              <div className="started-game-footer">
                <div className="started-game-context">
                  <img
                    src={startedGame.thumbnail}
                    className="started-game-img"
                  />
                  <div>
                    <h4>{startedGame.title}</h4>
                    <div className="provider-box">
                      <p>{startedGame.provider}</p>
                      <Lottie
                        className="live-json"
                        animationData={LiveAnimation}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  </div>
                </div>
                <button onClick={() => StopProduct(startedGame.gameid)}>
                  {t("Close")}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      }
    </div>
  );
}

export default Home;
