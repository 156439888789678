import React, { useState, useEffect, useContext } from "react";
import userService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import TransitionsModal from "./common/TransitionsModal";
import TransitionsModalPromotion from "./common/TransitionsModalPromotion";
import { useTranslation } from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import PromotionDescription, { formatPromotionMessage } from "./PromotionDescription"

const amountlist = [
    { id: 1, value: "10" },
    { id: 2, value: "30" },
    { id: 3, value: "50" },
    { id: 4, value: "100" },
    { id: 5, value: "200" },
    { id: 6, value: "250" },
    { id: 7, value: "300" },
    { id: 8, value: "500" },
    { id: 9, value: "1000" },
    { id: 10, value: "3000" },
    { id: 11, value: "5000" },
    { id: 12, value: "10000" },
  ];

const Online = ({vendor, title}) => {
    const initialValues = {
        ttype: "PG",
        amount: '',
        pg: vendor,
    };

    const [values, setValues] = useState(initialValues);
    // const [radioValue, setRadioValue] = useState(1);
    const [promotionList, setPromotionList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selectedTnC, setSelectedTnC] = useState({
        title: '',
        description: ''
    });
    const [validations, setValidations] = useState({
        amount: "",
        non_field_errors: ""
    })

  const configData = useContext(ConfigContext)
  const {t} = useTranslation();


    const {
        amount: amountVal,
        non_field_errors: nonVal,
    } = validations

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }

    // const onChangeRadio = (e) => {
    //     // setRadioValue(e.target.value);
    // };

    const onChangeRadio = (e) => {
        // setRadioValue(e.target.value);
        if (!e.target.checked) {
          setValues((preValues) => {
            const newVals = JSON.parse(JSON.stringify(preValues))
            delete newVals["promotion"]
            return { ...newVals };
          });
        } else {
          setValues((values) => {
            return { ...values, promotion: e.target.value };
          });
        }
      };

    const handleDeposit = async e => {
        e.preventDefault();

        setLoading(true);
        let response;
        try {
            response = await userService.createDeposit(values);


            const url = vendor == "dgpay"? response.data.action.paymentUrl : response.data.action.url;
            const bankdata = response.data.action.data;

            let form_data = new FormData();

            Object.entries(bankdata).forEach(([key, value]) => {
                form_data.append(`${key}`, value);
            })

            try {

                var form = document.createElement("form");

                form.method = "POST";
                form.action = url;

                let element = [{}];
                Object.entries(bankdata).forEach(([key, value], index) => {
                    element[index] = document.createElement("input");
                    element[index].name = key;
                    element[index].value = value;
                    form.appendChild(element[index]);
                })

                document.body.appendChild(form);
                form.style = "display: none";

                form.submit();

                document.body.removeChild(form);

            } catch (error) {
                console.log("error ==> ", error);
            }

            // TransitionsModal.success({
            //     content: <p>Deposit is successful. <br />
            //         Transaction ID: {response?.data?.id}</p>,
            // });

            // setValues(initialValues);
        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let non_msg;

            if (err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            // if (err?.response?.data?.cc) {
            //     cc_message = err.response.data.cc;
            // }
            setValidations({
                ...validations, amount: amount_msg, non_field_errors: non_msg
            });
        }

        setLoading(false);
    }

    const getPromotions = async () => {
        setLoading(true);
        const promotionListing = await userService.getPromotionsDepositList();
        // const nonObj = {
        //     id: 0,
        //     title: "NO PROMOTION",
        //     active: true
        // }

        // promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);

        setPromotionList(promotionListing.data.filter(v=>v.other.applicable));
        // setSelectedTnC(promotionListing.data[0]);
        // setRadioValue(promotionListing.data[0].id);
        setLoading(false);
    }

    useEffect(() => {
        getPromotions();
    }, [])
    return (
        <div className="">
            <h2 className="my-2" style={{color: configData?configData.text_color :""}}>{title}</h2>
            <div className="form-custom form-label my-4">
                <input
                    name="amount"
                    type="text"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder={t("Amount")}
                    onChange={handleNumChange}
                    value={values.amount}
                />
                <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
            </div>
            { vendor === "dgpay" &&
            <div className="form-custom form-label my-4">
                <select
                    style={{color: configData?configData.text_color :""}}
                    className="form-control rounded-xs"
                    value={values.provider} placeholder="Select Provider" onChange={(e)=>setValues({...values,provider:e.target.value})} name="provider">
                    <option value="">{t("Select_A_Value")}</option>
                    {
                        [{id:10,name:"Dana"},{id:11,name:"OVO"},{id:12,name:"ShopeePay"},{id:30,name:"Qris"}].map((payment, idx) => (
                            <option value={payment.id}>
                                {payment.name}
                            </option>
                        ))
                    }
                </select>
            </div>}
            <div className="d-flex text-center tab-scroll-wrap justify-content-between flex-wrap">
                {amountlist.map((item) => (
                    <div className="pr-10 zIndex0 mb-2" key={item.id} style={{ width: "calc(25% - 6px)" }}>
                        <div
                            className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value
                                    ? "border color-highlight"
                                    : "bg-highlight"
                                }`}
                            style={{ width: "100%" }}
                            onClick={() => setValues({ ...values, amount: item.value })}
                        >
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div> */}
            <div>
                {
                nonVal && nonVal.map((item, index) => (
                <div key={index}>
                    {formatPromotionMessage(item)}
                </div>
                ))
                }
            </div>
            {/* <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
                <Space direction="vertical">
                    {(promotionList && promotionList.length > 0) && promotionList.filter(item => item.active).map(item => {
                        return (
                            <Radio
                                value={item.id}
                                key={item.id}
                                onChange={() => setSelectedTnC(item)}
                                onClick={() => {
                                    setModalOpen(true);
                                    if (item.id !== 0) {
                                        setValues(values => {
                                            return { ...values, promotions_applied: item.id }
                                        })
                                    } else {
                                        delete values.promotions_applied;
                                    }
                                }}
                            >
                                {item.title}
                            </Radio>
                        )
                    })}
                </Space>
            </Radio.Group> */}
             <>
        <h4 className="mt-4" style={{color: configData?configData.text_color :""}}>{t("Bonus")} {t("Selection")} <span style={{color: configData?configData.text_color_secondary:""}}>({t("optional")}):</span></h4>
        <div className="px-2 border border-white">
          {promotionList &&
            promotionList.length > 0 &&
            promotionList
              .filter((item) => item.active)
              .map((item) => {
                return (
                  <div className="bonus-radio_container d-flex align-items-center my-2" key={item.id}>
                    <div className="bonus-selector d-flex position-relative" style={{ overflow: "hidden" }}>
                      <input className="bonus-radio" name="bonus-radio" type="checkbox" id="" value={item.id}
                    //   onChange={e=>{
                    //     // if(item.id === 0){
                    //     //   return
                    //     // }
                    //     setSelectedTnC(item)
                    //     onChangeRadio(e)
                    //   }}
                      onChange={e=>{
                        // if(item.id === 0){
                        //   return
                        // }
                        if (e.target.checked) {
                          setModalOpen(true);
                          setSelectedTnC(item)
                        } else {
                          setSelectedTnC({
                            title: "",
                            description: "",
                          })
                        }
                        onChangeRadio(e)
                      }}
                      checked={item.id == values.promotion}
                      onClick={() => {
                        // setModalOpen(true);
                        if (item.id !== 0) {
                          setValues((values) => {
                            return { ...values, promotion: item.id };
                          })
                        } else {
                          delete values.promotion;
                        }

                      }}
                      />
                      <div className="bonus-select p-2 d-flex justify-content-center align-items-center">
                        <span className="bonus-check">&#10004;</span>
                      </div>
                    </div>
                    <label className="ml-1 text-uppercase font-bold" style={{ color: configData?configData.text_color :"" }} htmlFor="">{item.title}</label>
                  </div>
                );
              })}
        </div>
        <p className="text-md d-flex mt-2 align-items-center" style={{color: configData?configData.text_color_secondary:"", gap: "5px", lineHeight: 1.5}}><InfoIcon /> {promotionList && promotionList.length ? t("PromotionMessage") : t("PromotionMessage2")}</p>
      </>

            <button
                className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
                style={{color: configData?configData.text_color :""}}
                onClick={handleDeposit}
            >
                {t("Deposit")}
            </button>
            <TransitionsModalPromotion
                title={selectedTnC.title}
                className="antd-promotion-modal"
                footer={[]}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <PromotionDescription
                  field={selectedTnC.description}
                  data={""}
                />
            </TransitionsModalPromotion>
            {/* <TransitionsModal
                title={selectedTnC.title}
                centered
                open={modalOpen}
                footer={[]}
                onClose={() => setModalOpen(false)}
            >
                <p className="text-lg font-semibold">Terms & Conditions</p>

                <div
                className="mt-2 promo_modal"
                dangerouslySetInnerHTML={{ __html: selectedTnC.description }}
                />
            </TransitionsModal> */}
            {
                isLoading && <Loading />
            }
        </div>
    );
};

export default Online;
