import React, {useState, useEffect, useContext } from "react";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import userService from "../service/user.service";
import authService from "../service/auth.service";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loading";

const initialValues = {
  amount: "",
  customer_bank_account: "",
  bank_number: "",
};

function Withdraw({walletInfo}) {
  // const { mode } = useContext(UserContext);

  const [values, setValues] = useState(initialValues);
  const [cuBanks, setCuBanks] = useState([]);
  const [bankNumber, setBankNumber] = useState();
  const [currentUser, setCurrentUser] = useState(null);
  const [isTxnModal, setIsTxnModal] = useState(false);
  const [txnId, setTxnId] = useState(false);
  const {t} = useTranslation();
  const [startedGames, setStartedGames] = useState([]);
  const configData = useContext(ConfigContext)
  const [isLoading, setLoading] = useState(false);
  const [creditTypes] = useState([
    {
      key: "1",
      value: "CA",
      label: t("Cash"),
    },
    {
      key: "2",
      value: "CH",
      label: t("Chips"),
    },
  ]);
  const [selectedCredit, setSelectedCredit] = useState("");

  const navigate = useNavigate();

  const [validations, setValidations] = useState({
    amount: "",
    customer_bank_account: "",
    non_field_errors: "",
    credit: ""
  });

  const {
    amount: amountVal,
    customer_bank_account: bank_Val,
    non_field_errors: nonVal,
    credit: creditValue,
  } = validations;

  const getCustomBanklist = async () => {
    const custombanks = await userService.getCustomBanks();
    let custombanklist = [];
    custombanks.data.forEach((game) => {
      if (game) {
        custombanklist.push({
          value: game.id,
          label: game.name,
          icon: game.icon,
          number: game.number,
        });
      }
    });
    setCuBanks(custombanklist);
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    // setLoading(true);

    try {
      const response = await userService.withdraw(values);
      setTxnId(response.data.id);
      setIsTxnModal(true);
      navigate("/history", {state: selectedCredit});

      setValues(initialValues);
    } catch (err) {
      console.log("err => ", err, values);
      let non_msg;
      let amount_msg;
      let bank_msg;
      let credit_msg;

      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.amount
      ) {
        amount_msg = err.response.data.amount;
      }
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.credit_type
      ) {
        credit_msg = err.response.data.credit_type;
      }
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.customer_bank_account
      ) {
        bank_msg = err.response.data.customer_bank_account;
      }
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.non_field_errors
      ) {
        non_msg = err.response.data.non_field_errors;
      }
      setValidations({
        ...validations,
        amount: amount_msg,
        customer_bank_account: bank_msg,
        non_field_errors: non_msg,
        credit: credit_msg,
      });
    }
    // setLoading(false);
  };

  const handleCreditChange = (e) => {
    setSelectedCredit(e.value);
    setValues((values) => {
      return { ...values, credit_type: e.value };
    });
  };

  const handleChange = (e) => {
    const bankNumber = cuBanks.find((item) => item.value === e.value).number;
    setBankNumber(bankNumber);
    setValues((values) => {
      return { ...values, customer_bank_account: e.value };
    });
  };

  // const handleNumChange = (e) => {
  //   const RE = /^\d*\.?\d{0,18}$/;
  //   if (RE.test(e.target.value) || e.target.value === "") {
  //     setValues((values) => {
  //       return { ...values, [e.target.name]: e.target.value };
  //     });
  //   }
  // };
  useEffect(() => {
    if (walletInfo && walletInfo.user && walletInfo.user.id) {
      getStartedGames(walletInfo.user.id);
    } 
  }, [walletInfo]);

  const maxAmount = (val) => {
    if (walletInfo) {
      setValues((values) => {
        return { ...values, amount: val };
      });
    }
  };

  const getUserInfo = async () => {
    const user = await authService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  };

  useEffect(() =>{
    if(selectedCredit){
      switch(selectedCredit){
        case 'CA':
          maxAmount(walletInfo.balance);
          break;
        case 'CH':
          maxAmount(walletInfo.chips_balance);
          break;
        default:
          maxAmount(0);
      }
    }
  },[selectedCredit]);
  
  const getStartedGames = async (currentUser) => {
    try {
      const startedProducts = await userService.getStartedGames(currentUser);
      if (startedProducts.data) {
        setStartedGames(startedProducts.data);
      }
    } catch (error) {
      setStartedGames([]);
    }
  };
  useEffect(() => {
    getCustomBanklist();
    getUserInfo();
  }, []);

  const StopProduct = async (id) => {
    setLoading(true);
    try {
      await userService.stopProduct(id);
    } catch (err) {
      console.log(err);
    }
    window.location.reload();
    setLoading(false);
  };

  return (
    <div className="t1">
      <div className="page">
        <div className="page-content">
          <div className="withdraw-img mb-3" style={{background: configData?configData.deposit_withdraw_title_bg :""}}>
            <img
              src={configData?configData.withdraw_title:""}
              alt=""
            />
          </div>
          <div className="mx-3 bg-sky-500 p-2 d-flex align-items-center justify-content-between mt-3" style={{borderRadius: "10px", backgroundColor: configData?configData.withdraw_chip_bg :""}}>
            <div className="w-50 d-flex justify-content-center align-items-center flex-col py-2" style={{borderRight: "1px solid #fff"}}>
              <div className="h6" style={{color: configData?configData.text_color:""}}>{t("Cash")}</div>
              <div className="mt-1" style={{fontSize: "1rem", color: configData?configData.text_color:""}}>
                {(currentUser && walletInfo) ? walletInfo.balance : "0.00"}
              </div>
            </div>
            <div className="w-50 d-flex justify-content-center align-items-center flex-col py-2">
              <div className="h6" style={{color: configData?configData.text_color:""}}>{t("Chips")}</div>
              <div className="mt-1" style={{fontSize: "1rem", color: configData?configData.text_color:""}}>
                {(currentUser && walletInfo) ? walletInfo.chips_balance : "0.00"}
              </div>
            </div>
          </div>
          <div
            className="mx-3 py-4 rounded-m"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Select
              options={cuBanks}
              formatOptionLabel={(bank) => (
                <div className="flex items-center gap-1" key={bank.id}>
                  <img
                    src={bank.icon}
                    alt=""
                    className="w-10 h-10"
                    style={{ marginRight: "5px", width: "40px" }}
                  />
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "10px",
                      flex: "0.8",
                      color: "black",
                    }}
                  >
                    {bank.label}
                  </span>
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "10px",
                      flex: "0.1",
                      color: "black",
                    }}
                  >
                    {bank.number}
                  </span>
                </div>
              )}
              onChange={handleChange}
              aria-label="Floating label select example"
              placeholder={`--${t("Choose")} ${t("Your_Account")}--`}
            />
            {bankNumber && (
              <div
                className="text-right text-sm"
                style={{ textAlign: "right", color: configData?configData.text_color :""  }}
              >
                {t("Bank")} {t("Account_Number")}: {bankNumber}
              </div>
            )}
            <div
              className={`${bank_Val === "" ? "valid-feedback" : "invalid-feedback"
                } `}
            >
              {bank_Val}
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Select
                onChange={handleCreditChange}
                defaultValue={selectedCredit}
                // placeholder="--Choose Credit Type--"
                placeholder={`--${t("Choose")} ${t("Credit_Type")}--`}
                options={creditTypes}
              />
              <div
                className={`${creditValue === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
              >
                {creditValue}
              </div>
            </div>

            <div className="mt-3 position-relative">
              <input
                type="number"
                className="form-control rounded-xs text-white"
                id="c1a"
                placeholder={t("Amount")}
                // onChange={handleNumChange}
                value={selectedCredit === "CA"?values.amount:values.chips_balance}
                name="amount"
                style={{ backgroundColor: "#1b1b1b", height: "53px" }}
              />
              {/* <div
                className="position-absolute w-fit bg-sky-600 px-2 right-0 font-bold rounded-full top-0 mt-2 cursor-pointer max-withdraw-btn"
                onClick={selectedCredit?maxAmount:()=>{}}
                style={{ backgroundColor: configData?configData.withdraw_chip_bg :"", color: configData?configData.text_color :"" }}
              >
                {t("MAX")}
              </div> */}
            </div>
            <div
              className={`${amountVal === "" ? "valid-feedback" : "invalid-feedback"
                } `}
            >
              {amountVal}
            </div>
            <div
              className={`${nonVal === "" ? "valid-feedback" : "invalid-feedback"
                } `}
            >
              {nonVal}
            </div>
            <div className="row">
              <div className="col-6" style={{ textAlign: "center "}}>
                <button
                  style={{
                    backgroundColor: configData ? configData.withdraw_chip_bg : "",
                    color: configData ? configData.text_color : ""
                  }}
                  className="btn btn-xl shadow-bg shadow-bg-s mt-4"
                  onClick={async (e) => {
                    setLoading(true)
                    startedGames.forEach(startedGame => {
                      StopProduct(startedGame.id)
                    })
                    setTimeout(() => {
                      setLoading(false)
                    }, 500);
                  }}
                >
                  {t("Refresh")}
                </button>
              </div>
              <div className="col-6" style={{ textAlign: "center "}}>
                <button
                  style={{
                    backgroundColor: configData ? configData.withdraw_chip_bg : "",
                    color: configData ? configData.text_color : ""
                  }}
                  className="btn btn-xl shadow-bg shadow-bg-s mt-4"
                  onClick={handleWithdraw}
                >
                  {t("Withdraw")}
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center" 
            style={{ color: "white", fontWeight: "bold", marginTop: "15px" }}>
              {t("Refresh_before_submit")}
            </div>
            
            {/* {mode = "light-mode" && <div className={`card-overlay rounded-0 m-0 bg-black-light opacity-70`}></div>} */}
          </div>
        </div>
        <Footer />
        <MenuNotification />
      </div>
      <Modal
        title="Congratulations!"
        style={{ top: "50px" }}
        open={isTxnModal}
        onCancel={() => setIsTxnModal(false)}
        footer={[]}
      >
        <h3 className="text-lg font-semibold">Withdraw is successful.</h3>
        <p className="font-semibold mt-1">
          Transaction Id: <span className="text-lime-700">{txnId}</span>
        </p>
        <div className="w-full d-flex justify-end gap-3 mt-3">
          <Button onClick={() => setIsTxnModal(false)}>Cancel</Button>
          <Button
            className="bg-sky-600 text-white"
            onClick={() => navigate("/")}
          >
            OK
          </Button>
        </div>
      </Modal>
      {isLoading && <Loading />}
    </div>
  );
}

export default Withdraw;
