import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import userService from "../service/user.service";
import Loading from "../components/Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

function EWallets() {
  const [ewalletsList, setEWalletsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();


  useEffect(() => {
    getMerchantEWalletslist();
  }, []);

  const getBackgroundClass = (item) => {
    if (item.name === "TNG") {
      return configData?configData.deposit_tng :"";
    } else if (item.name === "DQR" || item.name === "DuitNow" ) {
      return configData?configData.deposit_doitnow :"";
    }
    return "";
  };

  const getMerchantEWalletslist = async () => {
    setLoading(true);
    const ewallets = await userService.getMerchantEWallets();
    setEWalletsList(ewallets.data);
    setLoading(false);
  };

    return (
        <div className='page' style={{ height: 'calc(100vh - 114px)', overflow: 'scroll' }}>
            <div className="deposit-wrap d-flex flex-wrap justify-center align-center">
            <div className="deposit-title">
                <h6 className="deposit-texttitle" style={{ color: configData?configData.text_color_secondary :"" }}>{t("Choose_a_Payment_Method")}</h6>
            </div>
                <div className="mx-2 rounded-m">
                        {ewalletsList && ewalletsList.map((item, index) => {
                            return (
                                <div className={`deposit_payment`} key={index} onClick={() => navigate(`/ewallet/${item.id}`)}>
                                    <div className="tng-background" style={{backgroundImage: `url(${getBackgroundClass(item)})`}}>
                                        <div className="d-flex flex-col justify-center" style={{ width: "calc(100% - 45px)" }}></div>
                                    </div>
                                </div>

                            )
                        })}
                </div>
            </div>
            <div className="t1">
                <Footer />
            </div>
            <MenuNotification />
            {
                isLoading && <Loading />
            }
        </div>
    )
}

export default EWallets;
