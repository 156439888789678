import './component.css'
import React, { useContext, useEffect, useState } from "react";
import userService from "../service/user.service";
import { Empty, Tabs } from "antd";
import { toast } from "react-toastify";
import Footer from './Footer'
import TxTable from "./TxTable";
import Loading from "./Loading";
import { useLocation } from "react-router-dom";
import TransferList from './hisory/TransferList';
import { ConfigContext } from '../ConfigContext';

import { useTranslation } from 'react-i18next';
import RewardTable from './hisory/RewardTable';
import HistoryTabs2 from './layout/History/HistoryTabs2';
import History2 from './History2';

function HistoryTabs() {
  const [wallet, setWallet] = useState(null);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [chips, setChips] = useState([])
  const [rewards, setRewards] = useState([])
  const [bonuses, setBonuses] = useState([]);
  const [userId] = useState("");
  const [noItems, setNoItems] = useState(false);
  const [defaultTab, setDefaultTab] = useState('1')
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage, setDataPerPage] = useState(10)
  const {t} = useTranslation();

  const configData = useContext(ConfigContext)


  const [deleteTxn, setDeleteTxn] = useState(false);

  const {state} = useLocation()
  useEffect(() => {
    if(state && state === "CH") {
      setDefaultTab("3")
    } 
  }, [state])

  const getTransactions = async (userId) => {
    setLoading(true);
    try {
      const orderData = await userService.getOrders(userId);
      // const orderData = [];
      const transferData = await userService.getTransfers(userId);

      const chipsData = await userService.getChips(userId)

      const rewardData = await userService.getRewards()
      // const transferData = [];
      // const bonusData = await userService.getBonues(userId);
      if (!orderData.data.length && !transferData.data.length) {
        setNoItems(true);
      }
      if (orderData.data) {
        setOrders(orderData.data);
      }
      if (transferData.data) {
        setTransfers(transferData.data);
      }
      if(chipsData.data) {
        setChips(chipsData.data)
      }
      if(rewardData.data) {
        setRewards(rewardData.data)
      }
      // if (bonusData.data) {
      //   setBonuses(bonusData.data);
      // }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getWalletInfo = async () => {
    setLoading(true);
    try {
      const wallet = await userService.getBalance();
      setWallet(wallet.data);
      getTransactions(wallet.data.user.id);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCustomerBank = async () => {
    setLoading(true);
    try {
      const result = await userService.getCustomBanks();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleDelete = async (txnId, type) => {
    try {
      setLoading(true);
      if(type === "chip") {
        await userService.deleteChipTx(txnId)
      }else {
        await userService.deleteActor(userId, txnId);
      }

      if (type === "order") {
        const newItems = [...orders];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setOrders(filterItems);
      }
      if (type === "transfer") {
        const newItems = [...transfers];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setTransfers(filterItems);
      }
      if (type === "bonus") {
        const newItems = [...bonuses];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setBonuses(filterItems);
      }
      if (type === "chip") {
        const newItems = [...chips];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setChips(filterItems);
      }

      setDeleteTxn(!deleteTxn);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err)
    }
  };

  useEffect(() => {
    if (!wallet) {
      getWalletInfo();
    }
  }, [wallet]);

  useEffect(() => {
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchCustomerBank();
  }, []);

  const onChange = (key) => {
    setDefaultTab(key)
    setTimeout(() => {
      Array.from(document.querySelectorAll('.ant-tabs-tab .ant-tabs-tab-btn')).forEach((ele) =>{
        ele.style.background = "transparent"; 
        ele.style.color = "#ccc";

      })     

      Array.from(document.querySelectorAll('.ant-tabs-tab-active .ant-tabs-tab-btn')).forEach((ele) =>{
        ele.style.background = configData?configData.secondary_color:"";
        ele.style.color = configData?configData.text_color :"";
      }) 
    }, 100);
  };

  const lastDataIndex = currentPage * dataPerPage
  const firstDataIndex = lastDataIndex - dataPerPage
  const currentOrderData =  orders.slice(firstDataIndex, lastDataIndex)
  const orderPaginationData =[];
  for(let i=1; i<=Math.ceil(orders.length / dataPerPage); i++){
    orderPaginationData.push(i)
  }
  const currentChipData =  chips.slice(firstDataIndex, lastDataIndex)
  const chipPaginationData =[];
  for(let i=1; i<=Math.ceil(chips.length / dataPerPage); i++){
    chipPaginationData.push(i)
  }
  function prevPage(){
    if(currentPage !== firstDataIndex){
      setCurrentPage(currentPage - 1)
    }
  }
  function nextPage(){
    if(currentPage !== lastDataIndex){
      setCurrentPage(currentPage + 1)
    }
  }

  const items = [
    {
      key: "1",
      label: t(`Orders&Bonuses`),
      children: (
        <div>
          {orders.length > 0 ? (
            <>
              {/* {orders.map((order) => (
                <OrderItem
                  item={order} 
                  key={order.id}
                  handleDelete={handleDelete}
                  type="order"
                />
              ))} */}
              <TxTable
                orders={currentOrderData}
                handleDelete={handleDelete}
                type="order"
              />
               <nav aria-label="Page navigation example" style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                  <ul className="pagination mt-2" style={{border: `1px solid ${configData ? configData.border_color : ""}`, width:"fit-content", borderRadius:"5px"}}>
                      <li className="page-item" >
                        <a className="page-link" href="#"
                          style={{color: configData ? configData.text_color : "", background:"none", border:"none"}}
                          onClick={prevPage}>Prev</a>
                      </li>
                          {orderPaginationData.map((page, index) =>{
                          return <li key={index} 
                                    style={{background:configData && (page === currentPage)?configData.secondary_color:"",
                                  }}
                                    onClick={() => setCurrentPage(page)}
                                    className="page-item">
                                    <a className="page-link" href="#" style={{color: configData ? configData.text_color : "", background:"none", border:"none"}}>{page}</a>
                                  </li>
                          })}
                      <li className="page-item">
                        <a className="page-link" href="#"
                          style={{color: configData ? configData.text_color : "", background:"none", border:"none"}} 
                          onClick={nextPage}>Next</a>
                       </li>
                  </ul>
                </nav>
            </>
          ) : (
            <>
            {
              !loading && noItems &&
              <Empty description="No items found!" />
            }
            </>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: t(`Chips`),
      children: (
        <div>
          {chips.length > 0 ? (
            <>
              {/* {transfers.map((order) => (
                <OrderItem order={order} key={order.id} />
              ))} */}
              <TxTable
                orders={currentChipData}
                handleDelete={handleDelete}
                type="chip"
              />
                <nav aria-label="Page navigation example" style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                  <ul className="pagination mt-2" style={{border: `1px solid ${configData ? configData.border_color : ""}`, width:"fit-content", borderRadius:"5px"}}>
                      <li className="page-item" >
                        <a className="page-link" href="#"
                          style={{color: configData ? configData.text_color : "", background:"none", border:"none"}}
                          onClick={prevPage}>Prev</a>
                      </li>
                          {chipPaginationData.map((page, index) =>{
                          return <li key={index} 
                                    style={{background:configData && (page === currentPage)?configData.secondary_color:"",
                                  }}
                                    onClick={() => setCurrentPage(page)}
                                    className="page-item">
                                    <a className="page-link" href="#" style={{color: configData ? configData.text_color : "", background:"none", border:"none"}}>{page}</a>
                                  </li>
                          })}
                      <li className="page-item">
                        <a className="page-link" href="#"
                          style={{color: configData ? configData.text_color : "", background:"none", border:"none"}} 
                          onClick={nextPage}>Next</a>
                       </li>
                  </ul>
                </nav>
            </>
          ) : (
            <>
              <Empty description="No items found!" />
            </>
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: t(`Rewards`),
      children: (
        <div>
          {rewards && rewards.length > 0 ? (
            <>
              <RewardTable
                orders={rewards}
              />
            </>
          ) : (
            <>
              <Empty description="No items found!" />
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: t(`Transfers`),
      children: (
        <div>
          {transfers.length > 0 ? (
            <>
{/* {transfers.map((order) => (
                <OrderItem order={order} key={order.id} />
              ))} */}
              <TransferList
                orders={transfers}
                handleDelete={handleDelete}
                type="order"
              />
            </>
          ) : (
            <>
              <Empty description="No items found!" />
            </>
          )}
        </div>
      ),
    },
  ];


  return (
    <div className='t1'>
      <div className='page' >
        <div className="page-content footer-clear">
          <div id="menu-notifications"
            className="rounded-m d-block mx-2"
// style={{ backgroundColor: "#fff" }}
          >
            <div style={{ marginTop: "1rem " }}>
              {
                (configData && (configData.history_tabs_style == "1") || (configData && !configData.history_tabs_style))?
                <Tabs
                  defaultActiveKey={defaultTab}
                  activeKey={defaultTab}
                  items={items}
                  onChange={onChange}
                />
                :null
              }
              {
                configData && (configData.history_tabs_style == "2")?
                <HistoryTabs2 
                  defaultActiveKey={defaultTab}
                  activeKey={defaultTab}
                  items={items}
                  onChange={onChange}
                />
                :null
              }
              {
                configData && (configData.history_tabs_style == "3")?
                <History2
                  defaultActiveKey={defaultTab}
                  items={items}
                  onChange={onChange}
                  orders={currentOrderData}
                  handleDelete={handleDelete}
                  type="order"
                />
                :null
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {
        loading && <Loading />
      }
    </div>
  )
}

export default HistoryTabs